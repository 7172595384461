@charset "UTF-8";

/*------------------------------------------------------------------
[Components Stylesheet]

Project        :   Templist - HTML5 Premium Digital goods marketplace directory jquery css responsive website Template
Version        :   V.1
Create Date    :   28/02/20
Copyright      :   Spruko Technologies Private Limited 
Author         :   SprukoSoft
Author URL     :   https://themeforest.net/user/sprukosoft
Support		   :   support@spruko.com
License        :   Licensed under ThemeForest License

-------------------------------------------------------------------*/

/* ------ Table Of Contents
	* Widths, Margins & Paddings
*/


/*------ Widths, Margins & Paddings -------*/
.w-5{
	width:5% !important
}
.w-10{
	width: 10% !important;
}
.w-15{
	width: 15% !important;
}
.w-20{
	width: 20% !important;
}
.w-25 {
	width: 25% !important;
}
.w-30 {
	width: 30% !important;
}
.w-35 {
	width: 35% !important;
}
.w-36 {
	width: 36% !important;
}
.w-40 {
	width: 40% !important;
}
.w-45 {
	width: 45% !important;
}
.w-50 {
	width: 50% !important;
}
.w-55 {
	width: 55% !important;
}
.w-60 {
	width:60% !important;
}
.w-65 {
	width: 65% !important;
}
.w-70 {
	width: 70% !important;
}
.w-75 {
	width: 75% !important;
}
.w-80 {
	width: 80% !important;
}
.w-85 {
	width: 85% !important;
}
.w-90 {
	width: 90% !important;
}
.w-95 {
	width: 95% !important;
}
.w-100 {
	width: 100% !important;
}
.w100{
	width: 100px !important;
}
.h100{
	height: 100px !important;
}
.w73{
	width: 73px !important;
}
.h73{
	height: 73px !important;
}
.w-125{
	width: 125px !important;
}
.h-125{
	height: 125px !important;
}
.h-240{
	height: 240px !important;
}
.h-250{
	height: 250px !important;
}
.h-300{
	height: 300px !important;
}
.h-350{
	height: 350px !important;
}
.h-400{
	height: 400px !important;
}
.h-450{
	height: 450px !important;
}
.h-500{
	height: 500px !important;
}
.w-150{
	width: 150px !important;
}
.h-150{
	height: 150px !important;
}
.h-196{
	height: 196px !important;
}
.w-auto {
	width: auto !important;
}
.w-0 {
	width: 0 !important;
}
.w-1 {
	width: 0.25rem !important;
}
.w-2 {
	width: 0.5rem !important;
}
.w-3 {
	width: 0.75rem !important;
}
.w-4 {
	width: 1rem !important;
}
.w-5 {
	width: 1.5rem !important;
}
.w-6 {
	width: 2rem !important;
}
.w-7 {
	width: 3rem !important;
}
.w-8 {
	width: 4rem !important;
}
.w-9 {
	width: 6rem !important;
}
.h-25 {
	height: 25% !important;
}
.h-50 {
	height: 50% !important;
}
.h-75 {
	height: 75% !important;
}
.h-100 {
	height: 100% !important;
}
.h-auto {
	height: auto !important;
}
.h-0 {
	height: 0 !important;
}
.h-1 {
	height: 0.25rem !important;
}
.h-2 {
	height: 0.5rem !important;
}
.h-3 {
	height: 0.75rem !important;
}
.h-4 {
	height: 1rem !important;
}
.h-5 {
	height: 1.5rem !important;
}
.h-6 {
	height: 2rem !important;
}
.h-7 {
	height: 3rem !important;
}
.h-8 {
	height: 4rem !important;
}
.h-9 {
	height: 6rem !important;
}
.h-400{
	height: 400px !important;
}
.h-220{
	height: 220px !important;
}
.mw-100 {
	max-width: 100% !important;
}
.mh-100 {
	max-height: 100% !important;
}
.minh-210{
	min-height:210px !important
}
.m-0 {
	margin: 0 !important;
}
.mt-0, .my-0 {
	margin-top: 0 !important;
}
.mr-0, .mx-0 {
	margin-right: 0 !important;
}
.mb-0, .my-0 {
	margin-bottom: 0 !important;
}
.ml-0, .mx-0 {
	margin-left: 0 !important;
}
.m-1 {
	margin: 0.25rem !important;
}
.mt-1, .my-1 {
	margin-top: 0.25rem !important;
}
.mr-1, .mx-1 {
	margin-right: 0.25rem !important;
}
.mb-1, .my-1 {
	margin-bottom: 0.25rem !important;
}
.ml-1, .mx-1 {
	margin-left: 0.25rem !important;
}
.m-2 {
	margin: 0.5rem !important;
}
.mt-2, .my-2 {
	margin-top: 0.5rem !important;
}
.mr-2, .mx-2 {
	margin-right: 0.5rem !important;
}
.mb-2, .my-2 {
	margin-bottom: 0.5rem !important;
}
.ml-2, .mx-2 {
	margin-left: 0.5rem !important;
}
.m-3 {
	margin: 0.75rem !important;
}
.mt-3, .my-3 {
	margin-top: 0.75rem !important;
}
.mr-3, .mx-3 {
	margin-right: 0.75rem !important;
}
.mb-3, .my-3 {
	margin-bottom: 0.75rem !important;
}
.ml-3, .mx-3 {
	margin-left: 0.75rem !important;
}
.m-4 {
	margin: 1rem !important;
}
.mt-4, .my-4 {
	margin-top: 1rem !important;
}
.mr-4, .mx-4 {
	margin-right: 1rem !important;
}
.mb-4, .my-4 {
	margin-bottom: 1rem !important;
}
.ml-4, .mx-4 {
	margin-left: 1rem !important;
}
.m-5 {
	margin: 1.5rem !important;
}
.mt-5, .my-5 {
	margin-top: 1.5rem !important;
}
.mr-5, .mx-5 {
	margin-right: 1.5rem !important;
}
.mb-5, .my-5 {
	margin-bottom: 1.5rem !important;
}
.ml-5, .mx-5 {
	margin-left: 1.5rem !important;
}
.m-6 {
	margin: 2rem !important;
}
.mt-6, .my-6 {
	margin-top: 2rem !important;
}
.mr-6, .mx-6 {
	margin-right: 2rem !important;
}
.mb-6, .my-6 {
	margin-bottom: 2rem !important;
}
.ml-6, .mx-6 {
	margin-left: 2rem !important;
}
.m-7 {
	margin: 3rem !important;
}
.mt-7, .my-7 {
	margin-top: 3rem !important;
}
.mr-7, .mx-7 {
	margin-right: 3rem !important;
}
.mb-7, .my-7 {
	margin-bottom: 3rem !important;
}
.ml-7, .mx-7 {
	margin-left: 3rem !important;
}
.m-8 {
	margin: 4rem !important;
}
.mt-8, .my-8 {
	margin-top: 4rem !important;
}
.mr-8, .mx-8 {
	margin-right: 4rem !important;
}
.mb-8, .my-8 {
	margin-bottom: 4rem !important;
}
.ml-8, .mx-8 {
	margin-left: 4rem !important;
}
.m-9 {
	margin: 6rem !important;
}
.mt-9, .my-9 {
	margin-top: 6rem !important;
}
.mr-9, .mx-9 {
	margin-right: 6rem !important;
}
.mb-9, .my-9 {
	margin-bottom: 6rem !important;
}
.ml-9, .mx-9 {
	margin-left: 6rem !important;
}
.mt-10{
	margin-top: 9.95rem !important;
}
.p-0 {
	padding: 0 !important;
}
.pt-0, .py-0 {
	padding-top: 0 !important;
}
.pr-0, .px-0 {
	padding-right: 0 !important;
}
.pb-0, .py-0 {
	padding-bottom: 0 !important;
}
.pl-0, .px-0 {
	padding-left: 0 !important;
}
.p-1 {
	padding: 0.25rem !important;
}
.pt-1, .py-1 {
	padding-top: 0.25rem !important;
}
.pr-1, .px-1 {
	padding-right: 0.25rem !important;
}
.pb-1, .py-1 {
	padding-bottom: 0.25rem !important;
}
.pl-1, .px-1 {
	padding-left: 0.25rem !important;
}
.p-2 {
	padding: 0.5rem !important;
}
.pt-2, .py-2 {
	padding-top: 0.5rem !important;
}
.pr-2, .px-2 {
	padding-right: 0.5rem !important;
}
.pb-2, .py-2 {
	padding-bottom: 0.5rem !important;
}
.pl-2, .px-2 {
	padding-left: 0.5rem !important;
}
.p-3 {
	padding: 0.75rem !important;
}
.pt-3, .py-3 {
	padding-top: 0.75rem !important;
}
.pr-3, .px-3 {
	padding-right: 0.75rem !important;
}
.pb-3, .py-3 {
	padding-bottom: 0.75rem !important;
}
.pl-3, .px-3 {
	padding-left: 0.75rem !important;
}
.p-4 {
	padding: 1rem !important;
}
.pt-4, .py-4 {
	padding-top: 1rem !important;
}
.pr-4, .px-4 {
	padding-right: 1rem !important;
}
.pb-4, .py-4 {
	padding-bottom: 1rem !important;
}
.pl-4, .px-4 {
	padding-left: 1rem !important;
}
.p-5 {
	padding: 1.5rem !important;
}
.pt-5, .py-5 {
	padding-top: 1.5rem !important;
}
.pr-5, .px-5 {
	padding-right: 1.5rem !important;
}
.pb-5, .py-5 {
	padding-bottom: 1.5rem !important;
}
.pl-5, .px-5 {
	padding-left: 1.5rem !important;
}
.p-6 {
	padding: 2rem !important;
}
.pt-6, .py-6 {
	padding-top: 2rem !important;
}
.pr-6, .px-6 {
	padding-right: 2rem !important;
}
.pb-6, .py-6 {
	padding-bottom: 2rem !important;
}
.pl-6, .px-6 {
	padding-left: 2rem !important;
}
.p-7 {
	padding: 3rem !important;
}
.pt-7, .py-7 {
	padding-top: 3rem !important;
}
.pr-7, .px-7 {
	padding-right: 3rem !important;
}
.pb-7, .py-7 {
	padding-bottom: 3rem !important;
}
.pl-7, .px-7 {
	padding-left: 3rem !important;
}
.p-8 {
	padding: 4rem !important;
}
.pt-8, .py-8 {
	padding-top: 4rem !important;
}
.pr-8, .px-8 {
	padding-right: 4rem !important;
}
.pb-8, .py-8 {
	padding-bottom: 4rem !important;
}
.pl-8, .px-8 {
	padding-left: 4rem !important;
}
.p-9 {
	padding: 6rem !important;
}
.pt-9, .py-9 {
	padding-top: 6rem !important;
}
.pr-9, .px-9 {
	padding-right: 6rem !important;
}
.pb-9, .py-9 {
	padding-bottom: 6rem !important;
}
.pl-9, .px-9 {
	padding-left: 6rem !important;
}
.padding-bottom{
	padding-bottom: 5.47rem !important;
}
.margin-top{
	margin-top: 9.95rem !important;
}
.p-10 {
	padding: 9.3rem !important;
}
.pt-10, .py-10 {
	padding-top: 9.3rem !important;
}
.pr-10, .px-10 {
	padding-right: 9.3rem!important;
}
.pb-10, .py-10 {
	padding-bottom: 9.3rem !important;
}
.pl-10, .px-10 {
	padding-left: 9.3rem !important;
}
.m-auto {
	margin: auto !important;
}
.mt-auto, .my-auto {
	margin-top: auto !important;
}
.mr-auto, .mx-auto {
	margin-right: auto !important;
}
.mb-auto, .my-auto {
	margin-bottom: auto !important;
}
.ml-auto, .mx-auto {
	margin-left: auto !important;
}
@media (min-width: 576px) {
	.m-sm-0 {
		margin: 0 !important;
	}
	.mt-sm-0, .my-sm-0 {
		margin-top: 0 !important;
	}
	.mr-sm-0, .mx-sm-0 {
		margin-right: 0 !important;
	}
	.mb-sm-0, .my-sm-0 {
		margin-bottom: 0 !important;
	}
	.ml-sm-0, .mx-sm-0 {
		margin-left: 0 !important;
	}
	.m-sm-1 {
		margin: 0.25rem !important;
	}
	.mt-sm-1, .my-sm-1 {
		margin-top: 0.25rem !important;
	}
	.mr-sm-1, .mx-sm-1 {
		margin-right: 0.25rem !important;
	}
	.mb-sm-1, .my-sm-1 {
		margin-bottom: 0.25rem !important;
	}
	.ml-sm-1, .mx-sm-1 {
		margin-left: 0.25rem !important;
	}
	.m-sm-2 {
		margin: 0.5rem !important;
	}
	.mt-sm-2, .my-sm-2 {
		margin-top: 0.5rem !important;
	}
	.mr-sm-2, .mx-sm-2 {
		margin-right: 0.5rem !important;
	}
	.mb-sm-2, .my-sm-2 {
		margin-bottom: 0.5rem !important;
	}
	.ml-sm-2, .mx-sm-2 {
		margin-left: 0.5rem !important;
	}
	.m-sm-3 {
		margin: 0.75rem !important;
	}
	.mt-sm-3, .my-sm-3 {
		margin-top: 0.75rem !important;
	}
	.mr-sm-3, .mx-sm-3 {
		margin-right: 0.75rem !important;
	}
	.mb-sm-3, .my-sm-3 {
		margin-bottom: 0.75rem !important;
	}
	.ml-sm-3, .mx-sm-3 {
		margin-left: 0.75rem !important;
	}
	.m-sm-4 {
		margin: 1rem !important;
	}
	.mt-sm-4, .my-sm-4 {
		margin-top: 1rem !important;
	}
	.mr-sm-4, .mx-sm-4 {
		margin-right: 1rem !important;
	}
	.mb-sm-4, .my-sm-4 {
		margin-bottom: 1rem !important;
	}
	.ml-sm-4, .mx-sm-4 {
		margin-left: 1rem !important;
	}
	.m-sm-5 {
		margin: 1.5rem !important;
	}
	.mt-sm-5, .my-sm-5 {
		margin-top: 1.5rem !important;
	}
	.mr-sm-5, .mx-sm-5 {
		margin-right: 1.5rem !important;
	}
	.mb-sm-5, .my-sm-5 {
		margin-bottom: 1.5rem !important;
	}
	.ml-sm-5, .mx-sm-5 {
		margin-left: 1.5rem !important;
	}
	.m-sm-6 {
		margin: 2rem !important;
	}
	.mt-sm-6, .my-sm-6 {
		margin-top: 2rem !important;
	}
	.mr-sm-6, .mx-sm-6 {
		margin-right: 2rem !important;
	}
	.mb-sm-6, .my-sm-6 {
		margin-bottom: 2rem !important;
	}
	.ml-sm-6, .mx-sm-6 {
		margin-left: 2rem !important;
	}
	.m-sm-7 {
		margin: 3rem !important;
	}
	.mt-sm-7, .my-sm-7 {
		margin-top: 3rem !important;
	}
	.mr-sm-7, .mx-sm-7 {
		margin-right: 3rem !important;
	}
	.mb-sm-7, .my-sm-7 {
		margin-bottom: 3rem !important;
	}
	.ml-sm-7, .mx-sm-7 {
		margin-left: 3rem !important;
	}
	.m-sm-8 {
		margin: 4rem !important;
	}
	.mt-sm-8, .my-sm-8 {
		margin-top: 4rem !important;
	}
	.mr-sm-8, .mx-sm-8 {
		margin-right: 4rem !important;
	}
	.mb-sm-8, .my-sm-8 {
		margin-bottom: 4rem !important;
	}
	.ml-sm-8, .mx-sm-8 {
		margin-left: 4rem !important;
	}
	.m-sm-9 {
		margin: 6rem !important;
	}
	.mt-sm-9, .my-sm-9 {
		margin-top: 6rem !important;
	}
	.mr-sm-9, .mx-sm-9 {
		margin-right: 6rem !important;
	}
	.mb-sm-9, .my-sm-9 {
		margin-bottom: 6rem !important;
	}
	.ml-sm-9, .mx-sm-9 {
		margin-left: 6rem !important;
	}
	.p-sm-0 {
		padding: 0 !important;
	}
	.pt-sm-0, .py-sm-0 {
		padding-top: 0 !important;
	}
	.pr-sm-0, .px-sm-0 {
		padding-right: 0 !important;
	}
	.pb-sm-0, .py-sm-0 {
		padding-bottom: 0 !important;
	}
	.pl-sm-0, .px-sm-0 {
		padding-left: 0 !important;
	}
	.p-sm-1 {
		padding: 0.25rem !important;
	}
	.pt-sm-1, .py-sm-1 {
		padding-top: 0.25rem !important;
	}
	.pr-sm-1, .px-sm-1 {
		padding-right: 0.25rem !important;
	}
	.pb-sm-1, .py-sm-1 {
		padding-bottom: 0.25rem !important;
	}
	.pl-sm-1, .px-sm-1 {
		padding-left: 0.25rem !important;
	}
	.p-sm-2 {
		padding: 0.5rem !important;
	}
	.pt-sm-2, .py-sm-2 {
		padding-top: 0.5rem !important;
	}
	.pr-sm-2, .px-sm-2 {
		padding-right: 0.5rem !important;
	}
	.pb-sm-2, .py-sm-2 {
		padding-bottom: 0.5rem !important;
	}
	.pl-sm-2, .px-sm-2 {
		padding-left: 0.5rem !important;
	}
	.p-sm-3 {
		padding: 0.75rem !important;
	}
	.pt-sm-3, .py-sm-3 {
		padding-top: 0.75rem !important;
	}
	.pr-sm-3, .px-sm-3 {
		padding-right: 0.75rem !important;
	}
	.pb-sm-3, .py-sm-3 {
		padding-bottom: 0.75rem !important;
	}
	.pl-sm-3, .px-sm-3 {
		padding-left: 0.75rem !important;
	}
	.p-sm-4 {
		padding: 1rem !important;
	}
	.pt-sm-4, .py-sm-4 {
		padding-top: 1rem !important;
	}
	.pr-sm-4, .px-sm-4 {
		padding-right: 1rem !important;
	}
	.pb-sm-4, .py-sm-4 {
		padding-bottom: 1rem !important;
	}
	.pl-sm-4, .px-sm-4 {
		padding-left: 1rem !important;
	}
	.p-sm-5 {
		padding: 1.5rem !important;
	}
	.pt-sm-5, .py-sm-5 {
		padding-top: 1.5rem !important;
	}
	.pr-sm-5, .px-sm-5 {
		padding-right: 1.5rem !important;
	}
	.pb-sm-5, .py-sm-5 {
		padding-bottom: 1.5rem !important;
	}
	.pl-sm-5, .px-sm-5 {
		padding-left: 1.5rem !important;
	}
	.p-sm-6 {
		padding: 2rem !important;
	}
	.pt-sm-6, .py-sm-6 {
		padding-top: 2rem !important;
	}
	.pr-sm-6, .px-sm-6 {
		padding-right: 2rem !important;
	}
	.pb-sm-6, .py-sm-6 {
		padding-bottom: 2rem !important;
	}
	.pl-sm-6, .px-sm-6 {
		padding-left: 2rem !important;
	}
	.p-sm-7 {
		padding: 3rem !important;
	}
	.pt-sm-7, .py-sm-7 {
		padding-top: 3rem !important;
	}
	.pr-sm-7, .px-sm-7 {
		padding-right: 3rem !important;
	}
	.pb-sm-7, .py-sm-7 {
		padding-bottom: 3rem !important;
	}
	.pl-sm-7, .px-sm-7 {
		padding-left: 3rem !important;
	}
	.p-sm-8 {
		padding: 4rem !important;
	}
	.pt-sm-8, .py-sm-8 {
		padding-top: 4rem !important;
	}
	.pr-sm-8, .px-sm-8 {
		padding-right: 4rem !important;
	}
	.pb-sm-8, .py-sm-8 {
		padding-bottom: 4rem !important;
	}
	.pl-sm-8, .px-sm-8 {
		padding-left: 4rem !important;
	}
	.p-sm-9 {
		padding: 6rem !important;
	}
	.pt-sm-9, .py-sm-9 {
		padding-top: 6rem !important;
	}
	.pr-sm-9, .px-sm-9 {
		padding-right: 6rem !important;
	}
	.pb-sm-9, .py-sm-9 {
		padding-bottom: 6rem !important;
	}
	.pl-sm-9, .px-sm-9 {
		padding-left: 6rem !important;
	}
	.m-sm-auto {
		margin: auto !important;
	}
	.mt-sm-auto, .my-sm-auto {
		margin-top: auto !important;
	}
	.mr-sm-auto, .mx-sm-auto {
		margin-right: auto !important;
	}
	.mb-sm-auto, .my-sm-auto {
		margin-bottom: auto !important;
	}
	.ml-sm-auto, .mx-sm-auto {
		margin-left: auto !important;
	}
}
@media (min-width: 768px) {
	.m-md-0 {
		margin: 0 !important;
	}
	.mt-md-0, .my-md-0 {
		margin-top: 0 !important;
	}
	.mr-md-0, .mx-md-0 {
		margin-right: 0 !important;
	}
	.mb-md-0, .my-md-0 {
		margin-bottom: 0 !important;
	}
	.ml-md-0, .mx-md-0 {
		margin-left: 0 !important;
	}
	.m-md-1 {
		margin: 0.25rem !important;
	}
	.mt-md-1, .my-md-1 {
		margin-top: 0.25rem !important;
	}
	.mr-md-1, .mx-md-1 {
		margin-right: 0.25rem !important;
	}
	.mb-md-1, .my-md-1 {
		margin-bottom: 0.25rem !important;
	}
	.ml-md-1, .mx-md-1 {
		margin-left: 0.25rem !important;
	}
	.m-md-2 {
		margin: 0.5rem !important;
	}
	.mt-md-2, .my-md-2 {
		margin-top: 0.5rem !important;
	}
	.mr-md-2, .mx-md-2 {
		margin-right: 0.5rem !important;
	}
	.mb-md-2, .my-md-2 {
		margin-bottom: 0.5rem !important;
	}
	.ml-md-2, .mx-md-2 {
		margin-left: 0.5rem !important;
	}
	.m-md-3 {
		margin: 0.75rem !important;
	}
	.mt-md-3, .my-md-3 {
		margin-top: 0.75rem !important;
	}
	.mr-md-3, .mx-md-3 {
		margin-right: 0.75rem !important;
	}
	.mb-md-3, .my-md-3 {
		margin-bottom: 0.75rem !important;
	}
	.ml-md-3, .mx-md-3 {
		margin-left: 0.75rem !important;
	}
	.m-md-4 {
		margin: 1rem !important;
	}
	.mt-md-4, .my-md-4 {
		margin-top: 1rem !important;
	}
	.mr-md-4, .mx-md-4 {
		margin-right: 1rem !important;
	}
	.mb-md-4, .my-md-4 {
		margin-bottom: 1rem !important;
	}
	.ml-md-4, .mx-md-4 {
		margin-left: 1rem !important;
	}
	.m-md-5 {
		margin: 1.5rem !important;
	}
	.mt-md-5, .my-md-5 {
		margin-top: 1.5rem !important;
	}
	.mr-md-5, .mx-md-5 {
		margin-right: 1.5rem !important;
	}
	.mb-md-5, .my-md-5 {
		margin-bottom: 1.5rem !important;
	}
	.ml-md-5, .mx-md-5 {
		margin-left: 1.5rem !important;
	}
	.m-md-6 {
		margin: 2rem !important;
	}
	.mt-md-6, .my-md-6 {
		margin-top: 2rem !important;
	}
	.mr-md-6, .mx-md-6 {
		margin-right: 2rem !important;
	}
	.mb-md-6, .my-md-6 {
		margin-bottom: 2rem !important;
	}
	.ml-md-6, .mx-md-6 {
		margin-left: 2rem !important;
	}
	.m-md-7 {
		margin: 3rem !important;
	}
	.mt-md-7, .my-md-7 {
		margin-top: 3rem !important;
	}
	.mr-md-7, .mx-md-7 {
		margin-right: 3rem !important;
	}
	.mb-md-7, .my-md-7 {
		margin-bottom: 3rem !important;
	}
	.ml-md-7, .mx-md-7 {
		margin-left: 3rem !important;
	}
	.m-md-8 {
		margin: 4rem !important;
	}
	.mt-md-8, .my-md-8 {
		margin-top: 4rem !important;
	}
	.mr-md-8, .mx-md-8 {
		margin-right: 4rem !important;
	}
	.mb-md-8, .my-md-8 {
		margin-bottom: 4rem !important;
	}
	.ml-md-8, .mx-md-8 {
		margin-left: 4rem !important;
	}
	.m-md-9 {
		margin: 6rem !important;
	}
	.mt-md-9, .my-md-9 {
		margin-top: 6rem !important;
	}
	.mr-md-9, .mx-md-9 {
		margin-right: 6rem !important;
	}
	.mb-md-9, .my-md-9 {
		margin-bottom: 6rem !important;
	}
	.ml-md-9, .mx-md-9 {
		margin-left: 6rem !important;
	}
	.p-md-0 {
		padding: 0 !important;
	}
	.pt-md-0, .py-md-0 {
		padding-top: 0 !important;
	}
	.pr-md-0, .px-md-0 {
		padding-right: 0 !important;
	}
	.pb-md-0, .py-md-0 {
		padding-bottom: 0 !important;
	}
	.pl-md-0, .px-md-0 {
		padding-left: 0 !important;
	}
	.p-md-1 {
		padding: 0.25rem !important;
	}
	.pt-md-1, .py-md-1 {
		padding-top: 0.25rem !important;
	}
	.pr-md-1, .px-md-1 {
		padding-right: 0.25rem !important;
	}
	.pb-md-1, .py-md-1 {
		padding-bottom: 0.25rem !important;
	}
	.pl-md-1, .px-md-1 {
		padding-left: 0.25rem !important;
	}
	.p-md-2 {
		padding: 0.5rem !important;
	}
	.pt-md-2, .py-md-2 {
		padding-top: 0.5rem !important;
	}
	.pr-md-2, .px-md-2 {
		padding-right: 0.5rem !important;
	}
	.pb-md-2, .py-md-2 {
		padding-bottom: 0.5rem !important;
	}
	.pl-md-2, .px-md-2 {
		padding-left: 0.5rem !important;
	}
	.p-md-3 {
		padding: 0.75rem !important;
	}
	.pt-md-3, .py-md-3 {
		padding-top: 0.75rem !important;
	}
	.pr-md-3, .px-md-3 {
		padding-right: 0.75rem !important;
	}
	.pb-md-3, .py-md-3 {
		padding-bottom: 0.75rem !important;
	}
	.pl-md-3, .px-md-3 {
		padding-left: 0.75rem !important;
	}
	.p-md-4 {
		padding: 1rem !important;
	}
	.pt-md-4, .py-md-4 {
		padding-top: 1rem !important;
	}
	.pr-md-4, .px-md-4 {
		padding-right: 1rem !important;
	}
	.pb-md-4, .py-md-4 {
		padding-bottom: 1rem !important;
	}
	.pl-md-4, .px-md-4 {
		padding-left: 1rem !important;
	}
	.p-md-5 {
		padding: 1.5rem !important;
	}
	.pt-md-5, .py-md-5 {
		padding-top: 1.5rem !important;
	}
	.pr-md-5, .px-md-5 {
		padding-right: 1.5rem !important;
	}
	.pb-md-5, .py-md-5 {
		padding-bottom: 1.5rem !important;
	}
	.pl-md-5, .px-md-5 {
		padding-left: 1.5rem !important;
	}
	.p-md-6 {
		padding: 2rem !important;
	}
	.pt-md-6, .py-md-6 {
		padding-top: 2rem !important;
	}
	.pr-md-6, .px-md-6 {
		padding-right: 2rem !important;
	}
	.pb-md-6, .py-md-6 {
		padding-bottom: 2rem !important;
	}
	.pl-md-6, .px-md-6 {
		padding-left: 2rem !important;
	}
	.p-md-7 {
		padding: 3rem !important;
	}
	.pt-md-7, .py-md-7 {
		padding-top: 3rem !important;
	}
	.pr-md-7, .px-md-7 {
		padding-right: 3rem !important;
	}
	.pb-md-7, .py-md-7 {
		padding-bottom: 3rem !important;
	}
	.pl-md-7, .px-md-7 {
		padding-left: 3rem !important;
	}
	.p-md-8 {
		padding: 4rem !important;
	}
	.pt-md-8, .py-md-8 {
		padding-top: 4rem !important;
	}
	.pr-md-8, .px-md-8 {
		padding-right: 4rem !important;
	}
	.pb-md-8, .py-md-8 {
		padding-bottom: 4rem !important;
	}
	.pl-md-8, .px-md-8 {
		padding-left: 4rem !important;
	}
	.p-md-9 {
		padding: 6rem !important;
	}
	.pt-md-9, .py-md-9 {
		padding-top: 6rem !important;
	}
	.pr-md-9, .px-md-9 {
		padding-right: 6rem !important;
	}
	.pb-md-9, .py-md-9 {
		padding-bottom: 6rem !important;
	}
	.pl-md-9, .px-md-9 {
		padding-left: 6rem !important;
	}
	.m-md-auto {
		margin: auto !important;
	}
	.mt-md-auto, .my-md-auto {
		margin-top: auto !important;
	}
	.mr-md-auto, .mx-md-auto {
		margin-right: auto !important;
	}
	.mb-md-auto, .my-md-auto {
		margin-bottom: auto !important;
	}
	.ml-md-auto, .mx-md-auto {
		margin-left: auto !important;
	}
}
@media (min-width: 992px) {
	.m-lg-0 {
		margin: 0 !important;
	}
	.mt-lg-0, .my-lg-0 {
		margin-top: 0 !important;
	}
	.mr-lg-0, .mx-lg-0 {
		margin-right: 0 !important;
	}
	.mb-lg-0, .my-lg-0 {
		margin-bottom: 0 !important;
	}
	.ml-lg-0, .mx-lg-0 {
		margin-left: 0 !important;
	}
	.m-lg-1 {
		margin: 0.25rem !important;
	}
	.mt-lg-1, .my-lg-1 {
		margin-top: 0.25rem !important;
	}
	.mr-lg-1, .mx-lg-1 {
		margin-right: 0.25rem !important;
	}
	.mb-lg-1, .my-lg-1 {
		margin-bottom: 0.25rem !important;
	}
	.ml-lg-1, .mx-lg-1 {
		margin-left: 0.25rem !important;
	}
	.m-lg-2 {
		margin: 0.5rem !important;
	}
	.mt-lg-2, .my-lg-2 {
		margin-top: 0.5rem !important;
	}
	.mr-lg-2, .mx-lg-2 {
		margin-right: 0.5rem !important;
	}
	.mb-lg-2, .my-lg-2 {
		margin-bottom: 0.5rem !important;
	}
	.ml-lg-2, .mx-lg-2 {
		margin-left: 0.5rem !important;
	}
	.m-lg-3 {
		margin: 0.75rem !important;
	}
	.mt-lg-3, .my-lg-3 {
		margin-top: 0.75rem !important;
	}
	.mr-lg-3, .mx-lg-3 {
		margin-right: 0.75rem !important;
	}
	.mb-lg-3, .my-lg-3 {
		margin-bottom: 0.75rem !important;
	}
	.ml-lg-3, .mx-lg-3 {
		margin-left: 0.75rem !important;
	}
	.m-lg-4 {
		margin: 1rem !important;
	}
	.mt-lg-4, .my-lg-4 {
		margin-top: 1rem !important;
	}
	.mr-lg-4, .mx-lg-4 {
		margin-right: 1rem !important;
	}
	.mb-lg-4, .my-lg-4 {
		margin-bottom: 1rem !important;
	}
	.ml-lg-4, .mx-lg-4 {
		margin-left: 1rem !important;
	}
	.m-lg-5 {
		margin: 1.5rem !important;
	}
	.mt-lg-5, .my-lg-5 {
		margin-top: 1.5rem !important;
	}
	.mr-lg-5, .mx-lg-5 {
		margin-right: 1.5rem !important;
	}
	.mb-lg-5, .my-lg-5 {
		margin-bottom: 1.5rem !important;
	}
	.ml-lg-5, .mx-lg-5 {
		margin-left: 1.5rem !important;
	}
	.m-lg-6 {
		margin: 2rem !important;
	}
	.mt-lg-6, .my-lg-6 {
		margin-top: 2rem !important;
	}
	.mr-lg-6, .mx-lg-6 {
		margin-right: 2rem !important;
	}
	.mb-lg-6, .my-lg-6 {
		margin-bottom: 2rem !important;
	}
	.ml-lg-6, .mx-lg-6 {
		margin-left: 2rem !important;
	}
	.m-lg-7 {
		margin: 3rem !important;
	}
	.mt-lg-7, .my-lg-7 {
		margin-top: 3rem !important;
	}
	.mr-lg-7, .mx-lg-7 {
		margin-right: 3rem !important;
	}
	.mb-lg-7, .my-lg-7 {
		margin-bottom: 3rem !important;
	}
	.ml-lg-7, .mx-lg-7 {
		margin-left: 3rem !important;
	}
	.m-lg-8 {
		margin: 4rem !important;
	}
	.mt-lg-8, .my-lg-8 {
		margin-top: 4rem !important;
	}
	.mr-lg-8, .mx-lg-8 {
		margin-right: 4rem !important;
	}
	.mb-lg-8, .my-lg-8 {
		margin-bottom: 4rem !important;
	}
	.ml-lg-8, .mx-lg-8 {
		margin-left: 4rem !important;
	}
	.m-lg-9 {
		margin: 6rem !important;
	}
	.mt-lg-9, .my-lg-9 {
		margin-top: 6rem !important;
	}
	.mr-lg-9, .mx-lg-9 {
		margin-right: 6rem !important;
	}
	.mb-lg-9, .my-lg-9 {
		margin-bottom: 6rem !important;
	}
	.ml-lg-9, .mx-lg-9 {
		margin-left: 6rem !important;
	}
	.p-lg-0 {
		padding: 0 !important;
	}
	.pt-lg-0, .py-lg-0 {
		padding-top: 0 !important;
	}
	.pr-lg-0, .px-lg-0 {
		padding-right: 0 !important;
	}
	.pb-lg-0, .py-lg-0 {
		padding-bottom: 0 !important;
	}
	.pl-lg-0, .px-lg-0 {
		padding-left: 0 !important;
	}
	.p-lg-1 {
		padding: 0.25rem !important;
	}
	.pt-lg-1, .py-lg-1 {
		padding-top: 0.25rem !important;
	}
	.pr-lg-1, .px-lg-1 {
		padding-right: 0.25rem !important;
	}
	.pb-lg-1, .py-lg-1 {
		padding-bottom: 0.25rem !important;
	}
	.pl-lg-1, .px-lg-1 {
		padding-left: 0.25rem !important;
	}
	.p-lg-2 {
		padding: 0.5rem !important;
	}
	.pt-lg-2, .py-lg-2 {
		padding-top: 0.5rem !important;
	}
	.pr-lg-2, .px-lg-2 {
		padding-right: 0.5rem !important;
	}
	.pb-lg-2, .py-lg-2 {
		padding-bottom: 0.5rem !important;
	}
	.pl-lg-2, .px-lg-2 {
		padding-left: 0.5rem !important;
	}
	.p-lg-3 {
		padding: 0.75rem !important;
	}
	.pt-lg-3, .py-lg-3 {
		padding-top: 0.75rem !important;
	}
	.pr-lg-3, .px-lg-3 {
		padding-right: 0.75rem !important;
	}
	.pb-lg-3, .py-lg-3 {
		padding-bottom: 0.75rem !important;
	}
	.pl-lg-3, .px-lg-3 {
		padding-left: 0.75rem !important;
	}
	.p-lg-4 {
		padding: 1rem !important;
	}
	.pt-lg-4, .py-lg-4 {
		padding-top: 1rem !important;
	}
	.pr-lg-4, .px-lg-4 {
		padding-right: 1rem !important;
	}
	.pb-lg-4, .py-lg-4 {
		padding-bottom: 1rem !important;
	}
	.pl-lg-4, .px-lg-4 {
		padding-left: 1rem !important;
	}
	.p-lg-5 {
		padding: 1.5rem !important;
	}
	.pt-lg-5, .py-lg-5 {
		padding-top: 1.5rem !important;
	}
	.pr-lg-5, .px-lg-5 {
		padding-right: 1.5rem !important;
	}
	.pb-lg-5, .py-lg-5 {
		padding-bottom: 1.5rem !important;
	}
	.pl-lg-5, .px-lg-5 {
		padding-left: 1.5rem !important;
	}
	.p-lg-6 {
		padding: 2rem !important;
	}
	.pt-lg-6, .py-lg-6 {
		padding-top: 2rem !important;
	}
	.pr-lg-6, .px-lg-6 {
		padding-right: 2rem !important;
	}
	.pb-lg-6, .py-lg-6 {
		padding-bottom: 2rem !important;
	}
	.pl-lg-6, .px-lg-6 {
		padding-left: 2rem !important;
	}
	.p-lg-7 {
		padding: 3rem !important;
	}
	.pt-lg-7, .py-lg-7 {
		padding-top: 3rem !important;
	}
	.pr-lg-7, .px-lg-7 {
		padding-right: 3rem !important;
	}
	.pb-lg-7, .py-lg-7 {
		padding-bottom: 3rem !important;
	}
	.pl-lg-7, .px-lg-7 {
		padding-left: 3rem !important;
	}
	.p-lg-8 {
		padding: 4rem !important;
	}
	.pt-lg-8, .py-lg-8 {
		padding-top: 4rem !important;
	}
	.pr-lg-8, .px-lg-8 {
		padding-right: 4rem !important;
	}
	.pb-lg-8, .py-lg-8 {
		padding-bottom: 4rem !important;
	}
	.pl-lg-8, .px-lg-8 {
		padding-left: 4rem !important;
	}
	.p-lg-9 {
		padding: 6rem !important;
	}
	.pt-lg-9, .py-lg-9 {
		padding-top: 6rem !important;
	}
	.pr-lg-9, .px-lg-9 {
		padding-right: 6rem !important;
	}
	.pb-lg-9, .py-lg-9 {
		padding-bottom: 6rem !important;
	}
	.pl-lg-9, .px-lg-9 {
		padding-left: 6rem !important;
	}
	.m-lg-auto {
		margin: auto !important;
	}
	.mt-lg-auto, .my-lg-auto {
		margin-top: auto !important;
	}
	.mr-lg-auto, .mx-lg-auto {
		margin-right: auto !important;
	}
	.mb-lg-auto, .my-lg-auto {
		margin-bottom: auto !important;
	}
	.ml-lg-auto, .mx-lg-auto {
		margin-left: auto !important;
	}
}
@media (min-width: 1280px) {
	.m-xl-0 {
		margin: 0 !important;
	}
	.mt-xl-0, .my-xl-0 {
		margin-top: 0 !important;
	}
	.mr-xl-0, .mx-xl-0 {
		margin-right: 0 !important;
	}
	.mb-xl-0, .my-xl-0 {
		margin-bottom: 0 !important;
	}
	.ml-xl-0, .mx-xl-0 {
		margin-left: 0 !important;
	}
	.m-xl-1 {
		margin: 0.25rem !important;
	}
	.mt-xl-1, .my-xl-1 {
		margin-top: 0.25rem !important;
	}
	.mr-xl-1, .mx-xl-1 {
		margin-right: 0.25rem !important;
	}
	.mb-xl-1, .my-xl-1 {
		margin-bottom: 0.25rem !important;
	}
	.ml-xl-1, .mx-xl-1 {
		margin-left: 0.25rem !important;
	}
	.m-xl-2 {
		margin: 0.5rem !important;
	}
	.mt-xl-2, .my-xl-2 {
		margin-top: 0.5rem !important;
	}
	.mr-xl-2, .mx-xl-2 {
		margin-right: 0.5rem !important;
	}
	.mb-xl-2, .my-xl-2 {
		margin-bottom: 0.5rem !important;
	}
	.ml-xl-2, .mx-xl-2 {
		margin-left: 0.5rem !important;
	}
	.m-xl-3 {
		margin: 0.75rem !important;
	}
	.mt-xl-3, .my-xl-3 {
		margin-top: 0.75rem !important;
	}
	.mr-xl-3, .mx-xl-3 {
		margin-right: 0.75rem !important;
	}
	.mb-xl-3, .my-xl-3 {
		margin-bottom: 0.75rem !important;
	}
	.ml-xl-3, .mx-xl-3 {
		margin-left: 0.75rem !important;
	}
	.m-xl-4 {
		margin: 1rem !important;
	}
	.mt-xl-4, .my-xl-4 {
		margin-top: 1rem !important;
	}
	.mr-xl-4, .mx-xl-4 {
		margin-right: 1rem !important;
	}
	.mb-xl-4, .my-xl-4 {
		margin-bottom: 1rem !important;
	}
	.ml-xl-4, .mx-xl-4 {
		margin-left: 1rem !important;
	}
	.m-xl-5 {
		margin: 1.5rem !important;
	}
	.mt-xl-5, .my-xl-5 {
		margin-top: 1.5rem !important;
	}
	.mr-xl-5, .mx-xl-5 {
		margin-right: 1.5rem !important;
	}
	.mb-xl-5, .my-xl-5 {
		margin-bottom: 1.5rem !important;
	}
	.ml-xl-5, .mx-xl-5 {
		margin-left: 1.5rem !important;
	}
	.m-xl-6 {
		margin: 2rem !important;
	}
	.mt-xl-6, .my-xl-6 {
		margin-top: 2rem !important;
	}
	.mr-xl-6, .mx-xl-6 {
		margin-right: 2rem !important;
	}
	.mb-xl-6, .my-xl-6 {
		margin-bottom: 2rem !important;
	}
	.ml-xl-6, .mx-xl-6 {
		margin-left: 2rem !important;
	}
	.m-xl-7 {
		margin: 3rem !important;
	}
	.mt-xl-7, .my-xl-7 {
		margin-top: 3rem !important;
	}
	.mr-xl-7, .mx-xl-7 {
		margin-right: 3rem !important;
	}
	.mb-xl-7, .my-xl-7 {
		margin-bottom: 3rem !important;
	}
	.ml-xl-7, .mx-xl-7 {
		margin-left: 3rem !important;
	}
	.m-xl-8 {
		margin: 4rem !important;
	}
	.mt-xl-8, .my-xl-8 {
		margin-top: 4rem !important;
	}
	.mr-xl-8, .mx-xl-8 {
		margin-right: 4rem !important;
	}
	.mb-xl-8, .my-xl-8 {
		margin-bottom: 4rem !important;
	}
	.ml-xl-8, .mx-xl-8 {
		margin-left: 4rem !important;
	}
	.m-xl-9 {
		margin: 6rem !important;
	}
	.mt-xl-9, .my-xl-9 {
		margin-top: 6rem !important;
	}
	.mr-xl-9, .mx-xl-9 {
		margin-right: 6rem !important;
	}
	.mb-xl-9, .my-xl-9 {
		margin-bottom: 6rem !important;
	}
	.ml-xl-9, .mx-xl-9 {
		margin-left: 6rem !important;
	}
	.p-xl-0 {
		padding: 0 !important;
	}
	.pt-xl-0, .py-xl-0 {
		padding-top: 0 !important;
	}
	.pr-xl-0, .px-xl-0 {
		padding-right: 0 !important;
	}
	.pb-xl-0, .py-xl-0 {
		padding-bottom: 0 !important;
	}
	.pl-xl-0, .px-xl-0 {
		padding-left: 0 !important;
	}
	.p-xl-1 {
		padding: 0.25rem !important;
	}
	.pt-xl-1, .py-xl-1 {
		padding-top: 0.25rem !important;
	}
	.pr-xl-1, .px-xl-1 {
		padding-right: 0.25rem !important;
	}
	.pb-xl-1, .py-xl-1 {
		padding-bottom: 0.25rem !important;
	}
	.pl-xl-1, .px-xl-1 {
		padding-left: 0.25rem !important;
	}
	.p-xl-2 {
		padding: 0.5rem !important;
	}
	.pt-xl-2, .py-xl-2 {
		padding-top: 0.5rem !important;
	}
	.pr-xl-2, .px-xl-2 {
		padding-right: 0.5rem !important;
	}
	.pb-xl-2, .py-xl-2 {
		padding-bottom: 0.5rem !important;
	}
	.pl-xl-2, .px-xl-2 {
		padding-left: 0.5rem !important;
	}
	.p-xl-3 {
		padding: 0.75rem !important;
	}
	.pt-xl-3, .py-xl-3 {
		padding-top: 0.75rem !important;
	}
	.pr-xl-3, .px-xl-3 {
		padding-right: 0.75rem !important;
	}
	.pb-xl-3, .py-xl-3 {
		padding-bottom: 0.75rem !important;
	}
	.pl-xl-3, .px-xl-3 {
		padding-left: 0.75rem !important;
	}
	.p-xl-4 {
		padding: 1rem !important;
	}
	.pt-xl-4, .py-xl-4 {
		padding-top: 1rem !important;
	}
	.pr-xl-4, .px-xl-4 {
		padding-right: 1rem !important;
	}
	.pb-xl-4, .py-xl-4 {
		padding-bottom: 1rem !important;
	}
	.pl-xl-4, .px-xl-4 {
		padding-left: 1rem !important;
	}
	.p-xl-5 {
		padding: 1.5rem !important;
	}
	.pt-xl-5, .py-xl-5 {
		padding-top: 1.5rem !important;
	}
	.pr-xl-5, .px-xl-5 {
		padding-right: 1.5rem !important;
	}
	.pb-xl-5, .py-xl-5 {
		padding-bottom: 1.5rem !important;
	}
	.pl-xl-5, .px-xl-5 {
		padding-left: 1.5rem !important;
	}
	.p-xl-6 {
		padding: 2rem !important;
	}
	.pt-xl-6, .py-xl-6 {
		padding-top: 2rem !important;
	}
	.pr-xl-6, .px-xl-6 {
		padding-right: 2rem !important;
	}
	.pb-xl-6, .py-xl-6 {
		padding-bottom: 2rem !important;
	}
	.pl-xl-6, .px-xl-6 {
		padding-left: 2rem !important;
	}
	.p-xl-7 {
		padding: 3rem !important;
	}
	.pt-xl-7, .py-xl-7 {
		padding-top: 3rem !important;
	}
	.pr-xl-7, .px-xl-7 {
		padding-right: 3rem !important;
	}
	.pb-xl-7, .py-xl-7 {
		padding-bottom: 3rem !important;
	}
	.pl-xl-7, .px-xl-7 {
		padding-left: 3rem !important;
	}
	.p-xl-8 {
		padding: 4rem !important;
	}
	.pt-xl-8, .py-xl-8 {
		padding-top: 4rem !important;
	}
	.pr-xl-8, .px-xl-8 {
		padding-right: 4rem !important;
	}
	.pb-xl-8, .py-xl-8 {
		padding-bottom: 4rem !important;
	}
	.pl-xl-8, .px-xl-8 {
		padding-left: 4rem !important;
	}
	.p-xl-9 {
		padding: 6rem !important;
	}
	.pt-xl-9, .py-xl-9 {
		padding-top: 6rem !important;
	}
	.pr-xl-9, .px-xl-9 {
		padding-right: 6rem !important;
	}
	.pb-xl-9, .py-xl-9 {
		padding-bottom: 6rem !important;
	}
	.pl-xl-9, .px-xl-9 {
		padding-left: 6rem !important;
	}
	.m-xl-auto {
		margin: auto !important;
	}
	.mt-xl-auto, .my-xl-auto {
		margin-top: auto !important;
	}
	.mr-xl-auto, .mx-xl-auto {
		margin-right: auto !important;
	}
	.mb-xl-auto, .my-xl-auto {
		margin-bottom: auto !important;
	}
	.ml-xl-auto, .mx-xl-auto {
		margin-left: auto !important;
	}
}