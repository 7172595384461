/*------------------------------------------------------------------
[Custom Stylesheet]

Project        :   Templist - HTML5 Premium Digital goods marketplace directory jquery css responsive website Template
Version        :   V.1
Create Date    :   28/02/20
Copyright      :   Spruko Technologies Private Limited 
Author         :   SprukoSoft
Author URL     :   https://themeforest.net/user/sprukosoft
Support		   :   support@spruko.com
License        :   Licensed under ThemeForest License

-------------------------------------------------------------------*/

/** Custome Styles*/

.align-baseline {
	vertical-align: baseline!important;
}
.align-top {
	vertical-align: top!important;
}
.align-middle {
	vertical-align: middle!important;
}
.align-bottom {
	vertical-align: bottom!important;
}
.align-text-bottom {
	vertical-align: text-bottom!important;
}
.align-text-top {
	vertical-align: text-top!important;
}
.bg-secondary {
	background-color: #520995!important;
}
a.bg-secondary:focus, a.bg-secondary:hover, button.bg-secondary:focus, button.bg-secondary:hover {
	background-color: #520995!important;
}
.bg-success {
	background-color: #01d277!important;
}
a.bg-success:focus, a.bg-success:hover, button.bg-success:focus, button.bg-success:hover {
	background-color: #3adfab!important;
}
.bg-info {
	background-color:#0b94f7!important;
	box-shadow: 0 5px 10px rgba(11, 148, 247, 0.3);
}
a.bg-info:focus, a.bg-info:hover, button.bg-info:focus, button.bg-info:hover {
	background-color: #1594ef!important;
}
.bg-warning {
	background-color: #ffa22b!important;
	color: #fff;
}
a.bg-warning:focus, a.bg-warning:hover, button.bg-warning:focus, button.bg-warning:hover {
	background-color: #f59713!important;
}
.bg-danger {
	background-color: #f84242!important;
	box-shadow:0 5px 10px rgba(248, 66, 66, 0.3)
}
a.bg-danger:focus, a.bg-danger:hover, button.bg-danger:focus, button.bg-danger:hover {
	background-color: #f34a4a!important;
}
.bg-light {
	background-color: #e7e9f3 !important;
	box-shadow:0 5px 10px rgba(231, 233, 243, 0.3);
}
a.bg-light:focus, a.bg-light:hover, button.bg-light:focus, button.bg-light:hover {
	background-color: #e8e9f3!important;
}
.bg-dark {
	background-color:#0f2448 !important;
}
a.bg-dark:focus, a.bg-dark:hover, button.bg-dark:focus, button.bg-dark:hover {
	background-color: #0f0518!important;
}
.bg-white {
	background-color: #fff!important;
}
.bg-dark-purple {
	background-color: #413558!important;
}
a.bg-dark-purple:focus, a.bg-dark-purple:hover, button.bg-dark-purple:focus, button.bg-dark-purple:hover {
	background-color: #313031!important;
}
.bg-dark-purple {
	background-color: #373346 !important;
}
.bg-white {
	background-color: #fff!important;
}
.bg-transparent {
	background-color: transparent!important;
}
.border {
	border: 1px solid #dbddf1!important;
}
.border-top {
	border-top: 1px solid #dbddf1!important;
}
.border-right {
	border-right: 1px solid #dbddf1!important;
}
.border-bottom {
	border-bottom: 1px solid #dbddf1!important;
}
.border-left {
	border-left: 1px solid #dbddf1!important;
}
.border-0 {
	border: 0!important;
}
.border-top-0 {
	border-top: 0!important;
}
.border-right-0 {
	border-right: 0!important;
}
.border-bottom-0 {
	border-bottom: 0!important;
}
.border-left-0 {
	border-left: 0!important;
}
.border-success {
	border-color: #01d277!important;
}
.border-info {
	border-color:#0b94f7!important;
}
.border-warning {
	border-color: #ffa22b!important;
}
.border-danger {
	border-color: #f84242!important;
}
.border-light {
	border-color: #f8f9fa!important;
}
.border-dark {
	border-color: #343a40!important;
}
.border-white {
	border-color: #fff!important;
}
.rounded {
	border-radius: 4px!important;
}
.rounded7 {
	border-radius: 4px!important;
}
.rounded-top {
	border-top-left-radius: 4px!important;
	border-top-right-radius: 4px!important;
}
.rounded-right {
	border-top-right-radius: 4px!important;
	border-bottom-right-radius: 4px!important;
}
.rounded-bottom {
	border-bottom-right-radius: 4px!important;
	border-bottom-left-radius: 4px!important;
}
.rounded-left {
	border-top-left-radius: 4px!important;
	border-bottom-left-radius: 4px!important;
}
.rounded-circle {
	border-radius: 50%!important;
}
.rounded-0 {
	border-radius: 0!important;
}
.clearfix::after {
	display: block;
	clear: both;
	content: "";
}
.d-none {
	display: none!important;
}
.d-inline {
	display: inline!important;
}
.d-inline-block {
	display: inline-block!important;
}
.d-block {
	display: block!important;
}
.d-table {
	display: table!important;
}
.d-table-row {
	display: table-row!important;
}
.d-table-cell {
	display: table-cell!important;
}
.d-flex {
	display: -ms-flexbox!important;
	display: flex!important;
}
.d-inline-flex {
	display: -ms-inline-flexbox!important;
	display: inline-flex!important;
}
@media (min-width:576px) {
	.d-sm-none {
		display: none!important;
	}
	.d-sm-inline {
		display: inline!important;
	}
	.d-sm-inline-block {
		display: inline-block!important;
	}
	.d-sm-block {
		display: block!important;
	}
	.d-sm-table {
		display: table!important;
	}
	.d-sm-table-row {
		display: table-row!important;
	}
	.d-sm-table-cell {
		display: table-cell!important;
	}
	.d-sm-flex {
		display: -ms-flexbox!important;
		display: flex!important;
	}
	.d-sm-inline-flex {
		display: -ms-inline-flexbox!important;
		display: inline-flex!important;
	}
}
@media (min-width:768px) {
	.d-md-none {
		display: none!important;
	}
	.d-md-inline {
		display: inline!important;
	}
	.d-md-inline-block {
		display: inline-block!important;
	}
	.d-md-block {
		display: block!important;
	}
	.d-md-table {
		display: table!important;
	}
	.d-md-table-row {
		display: table-row!important;
	}
	.d-md-table-cell {
		display: table-cell!important;
	}
	.d-md-flex {
		display: -ms-flexbox!important;
		display: flex!important;
	}
	.d-md-inline-flex {
		display: -ms-inline-flexbox!important;
		display: inline-flex!important;
	}
}
@media (min-width:992px) {
	.d-lg-none {
		display: none!important;
	}
	.d-lg-inline {
		display: inline!important;
	}
	.d-lg-inline-block {
		display: inline-block!important;
	}
	.d-lg-block {
		display: block!important;
	}
	.d-lg-table {
		display: table!important;
	}
	.d-lg-table-row {
		display: table-row!important;
	}
	.d-lg-table-cell {
		display: table-cell!important;
	}
	.d-lg-flex {
		display: -ms-flexbox!important;
		display: flex!important;
	}
	.d-lg-inline-flex {
		display: -ms-inline-flexbox!important;
		display: inline-flex!important;
	}
}
@media (min-width:1280px) {
	.d-xl-none {
		display: none!important;
	}
	.d-xl-inline {
		display: inline!important;
	}
	.d-xl-inline-block {
		display: inline-block!important;
	}
	.d-xl-block {
		display: block!important;
	}
	.d-xl-table {
		display: table!important;
	}
	.d-xl-table-row {
		display: table-row!important;
	}
	.d-xl-table-cell {
		display: table-cell!important;
	}
	.d-xl-flex {
		display: -ms-flexbox!important;
		display: flex!important;
	}
	.d-xl-inline-flex {
		display: -ms-inline-flexbox!important;
		display: inline-flex!important;
	}
}
@media print {
	.d-print-none {
		display: none!important;
	}
	.d-print-inline {
		display: inline!important;
	}
	.d-print-inline-block {
		display: inline-block!important;
	}
	.d-print-block {
		display: block!important;
	}
	.d-print-table {
		display: table!important;
	}
	.d-print-table-row {
		display: table-row!important;
	}
	.d-print-table-cell {
		display: table-cell!important;
	}
	.d-print-flex {
		display: -ms-flexbox!important;
		display: flex!important;
	}
	.d-print-inline-flex {
		display: -ms-inline-flexbox!important;
		display: inline-flex!important;
	}
}
@media (max-width:576px) {
	.header-text h1 {
		font-size: 2rem;
	}
	.classified-video h1 {
		font-size: 1.2rem;
	}
	.section-title h2 {
		font-size: 1.5rem;
	}
	.content-text .display-5 {
		font-size: 1.8rem;
	}
}
.embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;
}
.embed-responsive::before {
	display: block;
	content: "";
}
.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}
.embed-responsive-21by9::before {
	padding-top: 42.85714286%;
}
.embed-responsive-16by9::before {
	padding-top: 56.25%;
}
.embed-responsive-4by3::before {
	padding-top: 75%;
}
.embed-responsive-1by1::before {
	padding-top: 100%;
}
.flex-row {
	-ms-flex-direction: row!important;
	flex-direction: row!important;
}
.flex-column {
	-ms-flex-direction: column!important;
	flex-direction: column!important;
}
.flex-row-reverse {
	-ms-flex-direction: row-reverse!important;
	flex-direction: row-reverse!important;
}
.flex-column-reverse {
	-ms-flex-direction: column-reverse!important;
	flex-direction: column-reverse!important;
}
.flex-wrap {
	-ms-flex-wrap: wrap!important;
	flex-wrap: wrap!important;
}
.flex-nowrap {
	-ms-flex-wrap: nowrap!important;
	flex-wrap: nowrap!important;
}
.flex-wrap-reverse {
	-ms-flex-wrap: wrap-reverse!important;
	flex-wrap: wrap-reverse!important;
}
.flex-fill {
	-ms-flex: 1 1 auto!important;
	flex: 1 1 auto!important;
}
.flex-grow-0 {
	-ms-flex-positive: 0!important;
	flex-grow: 0!important;
}
.flex-grow-1 {
	-ms-flex-positive: 1!important;
	flex-grow: 1!important;
}
.flex-shrink-0 {
	-ms-flex-negative: 0!important;
	flex-shrink: 0!important;
}
.flex-shrink-1 {
	-ms-flex-negative: 1!important;
	flex-shrink: 1!important;
}
.justify-content-start {
	-ms-flex-pack: start!important;
	justify-content: flex-start!important;
}
.justify-content-end {
	-ms-flex-pack: end!important;
	justify-content: flex-end!important;
}
.justify-content-center {
	-ms-flex-pack: center!important;
	justify-content: center!important;
}
.justify-content-between {
	-ms-flex-pack: justify!important;
	justify-content: space-between!important;
}
.justify-content-around {
	-ms-flex-pack: distribute!important;
	justify-content: space-around!important;
}
.align-items-start {
	-ms-flex-align: start!important;
	align-items: flex-start!important;
}
.align-items-end {
	-ms-flex-align: end!important;
	align-items: flex-end!important;
}
.align-items-center {
	-ms-flex-align: center!important;
	align-items: center!important;
}
.align-items-baseline {
	-ms-flex-align: baseline!important;
	align-items: baseline!important;
}
.align-items-stretch {
	-ms-flex-align: stretch!important;
	align-items: stretch!important;
}
.align-content-start {
	-ms-flex-line-pack: start!important;
	align-content: flex-start!important;
}
.align-content-end {
	-ms-flex-line-pack: end!important;
	align-content: flex-end!important;
}
.align-content-center {
	-ms-flex-line-pack: center!important;
	align-content: center!important;
}
.align-content-between {
	-ms-flex-line-pack: justify!important;
	align-content: space-between!important;
}
.align-content-around {
	-ms-flex-line-pack: distribute!important;
	align-content: space-around!important;
}
.align-content-stretch {
	-ms-flex-line-pack: stretch!important;
	align-content: stretch!important;
}
.align-self-auto {
	-ms-flex-item-align: auto!important;
	align-self: auto!important;
}
.align-self-start {
	-ms-flex-item-align: start!important;
	align-self: flex-start!important;
}
.align-self-end {
	-ms-flex-item-align: end!important;
	align-self: flex-end!important;
}
.align-self-center {
	-ms-flex-item-align: center!important;
	align-self: center!important;
}
.align-self-baseline {
	-ms-flex-item-align: baseline!important;
	align-self: baseline!important;
}
.align-self-stretch {
	-ms-flex-item-align: stretch!important;
	align-self: stretch!important;
}
@media (min-width:576px) {
	.flex-sm-row {
		-ms-flex-direction: row!important;
		flex-direction: row!important;
	}
	.flex-sm-column {
		-ms-flex-direction: column!important;
		flex-direction: column!important;
	}
	.flex-sm-row-reverse {
		-ms-flex-direction: row-reverse!important;
		flex-direction: row-reverse!important;
	}
	.flex-sm-column-reverse {
		-ms-flex-direction: column-reverse!important;
		flex-direction: column-reverse!important;
	}
	.flex-sm-wrap {
		-ms-flex-wrap: wrap!important;
		flex-wrap: wrap!important;
	}
	.flex-sm-nowrap {
		-ms-flex-wrap: nowrap!important;
		flex-wrap: nowrap!important;
	}
	.flex-sm-wrap-reverse {
		-ms-flex-wrap: wrap-reverse!important;
		flex-wrap: wrap-reverse!important;
	}
	.flex-sm-fill {
		-ms-flex: 1 1 auto!important;
		flex: 1 1 auto!important;
	}
	.flex-sm-grow-0 {
		-ms-flex-positive: 0!important;
		flex-grow: 0!important;
	}
	.flex-sm-grow-1 {
		-ms-flex-positive: 1!important;
		flex-grow: 1!important;
	}
	.flex-sm-shrink-0 {
		-ms-flex-negative: 0!important;
		flex-shrink: 0!important;
	}
	.flex-sm-shrink-1 {
		-ms-flex-negative: 1!important;
		flex-shrink: 1!important;
	}
	.justify-content-sm-start {
		-ms-flex-pack: start!important;
		justify-content: flex-start!important;
	}
	.justify-content-sm-end {
		-ms-flex-pack: end!important;
		justify-content: flex-end!important;
	}
	.justify-content-sm-center {
		-ms-flex-pack: center!important;
		justify-content: center!important;
	}
	.justify-content-sm-between {
		-ms-flex-pack: justify!important;
		justify-content: space-between!important;
	}
	.justify-content-sm-around {
		-ms-flex-pack: distribute!important;
		justify-content: space-around!important;
	}
	.align-items-sm-start {
		-ms-flex-align: start!important;
		align-items: flex-start!important;
	}
	.align-items-sm-end {
		-ms-flex-align: end!important;
		align-items: flex-end!important;
	}
	.align-items-sm-center {
		-ms-flex-align: center!important;
		align-items: center!important;
	}
	.align-items-sm-baseline {
		-ms-flex-align: baseline!important;
		align-items: baseline!important;
	}
	.align-items-sm-stretch {
		-ms-flex-align: stretch!important;
		align-items: stretch!important;
	}
	.align-content-sm-start {
		-ms-flex-line-pack: start!important;
		align-content: flex-start!important;
	}
	.align-content-sm-end {
		-ms-flex-line-pack: end!important;
		align-content: flex-end!important;
	}
	.align-content-sm-center {
		-ms-flex-line-pack: center!important;
		align-content: center!important;
	}
	.align-content-sm-between {
		-ms-flex-line-pack: justify!important;
		align-content: space-between!important;
	}
	.align-content-sm-around {
		-ms-flex-line-pack: distribute!important;
		align-content: space-around!important;
	}
	.align-content-sm-stretch {
		-ms-flex-line-pack: stretch!important;
		align-content: stretch!important;
	}
	.align-self-sm-auto {
		-ms-flex-item-align: auto!important;
		align-self: auto!important;
	}
	.align-self-sm-start {
		-ms-flex-item-align: start!important;
		align-self: flex-start!important;
	}
	.align-self-sm-end {
		-ms-flex-item-align: end!important;
		align-self: flex-end!important;
	}
	.align-self-sm-center {
		-ms-flex-item-align: center!important;
		align-self: center!important;
	}
	.align-self-sm-baseline {
		-ms-flex-item-align: baseline!important;
		align-self: baseline!important;
	}
	.align-self-sm-stretch {
		-ms-flex-item-align: stretch!important;
		align-self: stretch!important;
	}
}
@media (min-width:768px) {
	.flex-md-row {
		-ms-flex-direction: row!important;
		flex-direction: row!important;
	}
	.flex-md-column {
		-ms-flex-direction: column!important;
		flex-direction: column!important;
	}
	.flex-md-row-reverse {
		-ms-flex-direction: row-reverse!important;
		flex-direction: row-reverse!important;
	}
	.flex-md-column-reverse {
		-ms-flex-direction: column-reverse!important;
		flex-direction: column-reverse!important;
	}
	.flex-md-wrap {
		-ms-flex-wrap: wrap!important;
		flex-wrap: wrap!important;
	}
	.flex-md-nowrap {
		-ms-flex-wrap: nowrap!important;
		flex-wrap: nowrap!important;
	}
	.flex-md-wrap-reverse {
		-ms-flex-wrap: wrap-reverse!important;
		flex-wrap: wrap-reverse!important;
	}
	.flex-md-fill {
		-ms-flex: 1 1 auto!important;
		flex: 1 1 auto!important;
	}
	.flex-md-grow-0 {
		-ms-flex-positive: 0!important;
		flex-grow: 0!important;
	}
	.flex-md-grow-1 {
		-ms-flex-positive: 1!important;
		flex-grow: 1!important;
	}
	.flex-md-shrink-0 {
		-ms-flex-negative: 0!important;
		flex-shrink: 0!important;
	}
	.flex-md-shrink-1 {
		-ms-flex-negative: 1!important;
		flex-shrink: 1!important;
	}
	.justify-content-md-start {
		-ms-flex-pack: start!important;
		justify-content: flex-start!important;
	}
	.justify-content-md-end {
		-ms-flex-pack: end!important;
		justify-content: flex-end!important;
	}
	.justify-content-md-center {
		-ms-flex-pack: center!important;
		justify-content: center!important;
	}
	.justify-content-md-between {
		-ms-flex-pack: justify!important;
		justify-content: space-between!important;
	}
	.justify-content-md-around {
		-ms-flex-pack: distribute!important;
		justify-content: space-around!important;
	}
	.align-items-md-start {
		-ms-flex-align: start!important;
		align-items: flex-start!important;
	}
	.align-items-md-end {
		-ms-flex-align: end!important;
		align-items: flex-end!important;
	}
	.align-items-md-center {
		-ms-flex-align: center!important;
		align-items: center!important;
	}
	.align-items-md-baseline {
		-ms-flex-align: baseline!important;
		align-items: baseline!important;
	}
	.align-items-md-stretch {
		-ms-flex-align: stretch!important;
		align-items: stretch!important;
	}
	.align-content-md-start {
		-ms-flex-line-pack: start!important;
		align-content: flex-start!important;
	}
	.align-content-md-end {
		-ms-flex-line-pack: end!important;
		align-content: flex-end!important;
	}
	.align-content-md-center {
		-ms-flex-line-pack: center!important;
		align-content: center!important;
	}
	.align-content-md-between {
		-ms-flex-line-pack: justify!important;
		align-content: space-between!important;
	}
	.align-content-md-around {
		-ms-flex-line-pack: distribute!important;
		align-content: space-around!important;
	}
	.align-content-md-stretch {
		-ms-flex-line-pack: stretch!important;
		align-content: stretch!important;
	}
	.align-self-md-auto {
		-ms-flex-item-align: auto!important;
		align-self: auto!important;
	}
	.align-self-md-start {
		-ms-flex-item-align: start!important;
		align-self: flex-start!important;
	}
	.align-self-md-end {
		-ms-flex-item-align: end!important;
		align-self: flex-end!important;
	}
	.align-self-md-center {
		-ms-flex-item-align: center!important;
		align-self: center!important;
	}
	.align-self-md-baseline {
		-ms-flex-item-align: baseline!important;
		align-self: baseline!important;
	}
	.align-self-md-stretch {
		-ms-flex-item-align: stretch!important;
		align-self: stretch!important;
	}
}
@media (min-width:992px) {
	.flex-lg-row {
		-ms-flex-direction: row!important;
		flex-direction: row!important;
	}
	.flex-lg-column {
		-ms-flex-direction: column!important;
		flex-direction: column!important;
	}
	.flex-lg-row-reverse {
		-ms-flex-direction: row-reverse!important;
		flex-direction: row-reverse!important;
	}
	.flex-lg-column-reverse {
		-ms-flex-direction: column-reverse!important;
		flex-direction: column-reverse!important;
	}
	.flex-lg-wrap {
		-ms-flex-wrap: wrap!important;
		flex-wrap: wrap!important;
	}
	.flex-lg-nowrap {
		-ms-flex-wrap: nowrap!important;
		flex-wrap: nowrap!important;
	}
	.flex-lg-wrap-reverse {
		-ms-flex-wrap: wrap-reverse!important;
		flex-wrap: wrap-reverse!important;
	}
	.flex-lg-fill {
		-ms-flex: 1 1 auto!important;
		flex: 1 1 auto!important;
	}
	.flex-lg-grow-0 {
		-ms-flex-positive: 0!important;
		flex-grow: 0!important;
	}
	.flex-lg-grow-1 {
		-ms-flex-positive: 1!important;
		flex-grow: 1!important;
	}
	.flex-lg-shrink-0 {
		-ms-flex-negative: 0!important;
		flex-shrink: 0!important;
	}
	.flex-lg-shrink-1 {
		-ms-flex-negative: 1!important;
		flex-shrink: 1!important;
	}
	.justify-content-lg-start {
		-ms-flex-pack: start!important;
		justify-content: flex-start!important;
	}
	.justify-content-lg-end {
		-ms-flex-pack: end!important;
		justify-content: flex-end!important;
	}
	.justify-content-lg-center {
		-ms-flex-pack: center!important;
		justify-content: center!important;
	}
	.justify-content-lg-between {
		-ms-flex-pack: justify!important;
		justify-content: space-between!important;
	}
	.justify-content-lg-around {
		-ms-flex-pack: distribute!important;
		justify-content: space-around!important;
	}
	.align-items-lg-start {
		-ms-flex-align: start!important;
		align-items: flex-start!important;
	}
	.align-items-lg-end {
		-ms-flex-align: end!important;
		align-items: flex-end!important;
	}
	.align-items-lg-center {
		-ms-flex-align: center!important;
		align-items: center!important;
	}
	.align-items-lg-baseline {
		-ms-flex-align: baseline!important;
		align-items: baseline!important;
	}
	.align-items-lg-stretch {
		-ms-flex-align: stretch!important;
		align-items: stretch!important;
	}
	.align-content-lg-start {
		-ms-flex-line-pack: start!important;
		align-content: flex-start!important;
	}
	.align-content-lg-end {
		-ms-flex-line-pack: end!important;
		align-content: flex-end!important;
	}
	.align-content-lg-center {
		-ms-flex-line-pack: center!important;
		align-content: center!important;
	}
	.align-content-lg-between {
		-ms-flex-line-pack: justify!important;
		align-content: space-between!important;
	}
	.align-content-lg-around {
		-ms-flex-line-pack: distribute!important;
		align-content: space-around!important;
	}
	.align-content-lg-stretch {
		-ms-flex-line-pack: stretch!important;
		align-content: stretch!important;
	}
	.align-self-lg-auto {
		-ms-flex-item-align: auto!important;
		align-self: auto!important;
	}
	.align-self-lg-start {
		-ms-flex-item-align: start!important;
		align-self: flex-start!important;
	}
	.align-self-lg-end {
		-ms-flex-item-align: end!important;
		align-self: flex-end!important;
	}
	.align-self-lg-center {
		-ms-flex-item-align: center!important;
		align-self: center!important;
	}
	.align-self-lg-baseline {
		-ms-flex-item-align: baseline!important;
		align-self: baseline!important;
	}
	.align-self-lg-stretch {
		-ms-flex-item-align: stretch!important;
		align-self: stretch!important;
	}
}
@media (min-width:1280px) {
	.flex-xl-row {
		-ms-flex-direction: row!important;
		flex-direction: row!important;
	}
	.flex-xl-column {
		-ms-flex-direction: column!important;
		flex-direction: column!important;
	}
	.flex-xl-row-reverse {
		-ms-flex-direction: row-reverse!important;
		flex-direction: row-reverse!important;
	}
	.flex-xl-column-reverse {
		-ms-flex-direction: column-reverse!important;
		flex-direction: column-reverse!important;
	}
	.flex-xl-wrap {
		-ms-flex-wrap: wrap!important;
		flex-wrap: wrap!important;
	}
	.flex-xl-nowrap {
		-ms-flex-wrap: nowrap!important;
		flex-wrap: nowrap!important;
	}
	.flex-xl-wrap-reverse {
		-ms-flex-wrap: wrap-reverse!important;
		flex-wrap: wrap-reverse!important;
	}
	.flex-xl-fill {
		-ms-flex: 1 1 auto!important;
		flex: 1 1 auto!important;
	}
	.flex-xl-grow-0 {
		-ms-flex-positive: 0!important;
		flex-grow: 0!important;
	}
	.flex-xl-grow-1 {
		-ms-flex-positive: 1!important;
		flex-grow: 1!important;
	}
	.flex-xl-shrink-0 {
		-ms-flex-negative: 0!important;
		flex-shrink: 0!important;
	}
	.flex-xl-shrink-1 {
		-ms-flex-negative: 1!important;
		flex-shrink: 1!important;
	}
	.justify-content-xl-start {
		-ms-flex-pack: start!important;
		justify-content: flex-start!important;
	}
	.justify-content-xl-end {
		-ms-flex-pack: end!important;
		justify-content: flex-end!important;
	}
	.justify-content-xl-center {
		-ms-flex-pack: center!important;
		justify-content: center!important;
	}
	.justify-content-xl-between {
		-ms-flex-pack: justify!important;
		justify-content: space-between!important;
	}
	.justify-content-xl-around {
		-ms-flex-pack: distribute!important;
		justify-content: space-around!important;
	}
	.align-items-xl-start {
		-ms-flex-align: start!important;
		align-items: flex-start!important;
	}
	.align-items-xl-end {
		-ms-flex-align: end!important;
		align-items: flex-end!important;
	}
	.align-items-xl-center {
		-ms-flex-align: center!important;
		align-items: center!important;
	}
	.align-items-xl-baseline {
		-ms-flex-align: baseline!important;
		align-items: baseline!important;
	}
	.align-items-xl-stretch {
		-ms-flex-align: stretch!important;
		align-items: stretch!important;
	}
	.align-content-xl-start {
		-ms-flex-line-pack: start!important;
		align-content: flex-start!important;
	}
	.align-content-xl-end {
		-ms-flex-line-pack: end!important;
		align-content: flex-end!important;
	}
	.align-content-xl-center {
		-ms-flex-line-pack: center!important;
		align-content: center!important;
	}
	.align-content-xl-between {
		-ms-flex-line-pack: justify!important;
		align-content: space-between!important;
	}
	.align-content-xl-around {
		-ms-flex-line-pack: distribute!important;
		align-content: space-around!important;
	}
	.align-content-xl-stretch {
		-ms-flex-line-pack: stretch!important;
		align-content: stretch!important;
	}
	.align-self-xl-auto {
		-ms-flex-item-align: auto!important;
		align-self: auto!important;
	}
	.align-self-xl-start {
		-ms-flex-item-align: start!important;
		align-self: flex-start!important;
	}
	.align-self-xl-end {
		-ms-flex-item-align: end!important;
		align-self: flex-end!important;
	}
	.align-self-xl-center {
		-ms-flex-item-align: center!important;
		align-self: center!important;
	}
	.align-self-xl-baseline {
		-ms-flex-item-align: baseline!important;
		align-self: baseline!important;
	}
	.align-self-xl-stretch {
		-ms-flex-item-align: stretch!important;
		align-self: stretch!important;
	}
}
.float-left {
	float: left!important;
}
.float-right {
	float: right!important;
}
.float-none {
	float: none!important;
}
@media (min-width:576px) {
	.float-sm-left {
		float: left!important;
	}
	.float-sm-right {
		float: right!important;
	}
	.float-sm-none {
		float: none!important;
	}
}
@media (min-width:768px) {
	.float-md-left {
		float: left!important;
	}
	.float-md-right {
		float: right!important;
	}
	.float-md-none {
		float: none!important;
	}
}
@media (min-width:992px) {
	.float-lg-left {
		float: left!important;
	}
	.float-lg-right {
		float: right!important;
	}
	.float-lg-none {
		float: none!important;
	}
}
@media (min-width:1280px) {
	.float-xl-left {
		float: left!important;
	}
	.float-xl-right {
		float: right!important;
	}
	.float-xl-none {
		float: none!important;
	}
}
.position-static {
	position: static!important;
}
.position-relative {
	position: relative!important;
}
.position-absolute {
	position: absolute!important;
}
.position-fixed {
	position: fixed!important;
}
.position-sticky {
	position: -webkit-sticky!important;
	position: sticky!important;
}
.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}
.fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030;
}
@supports ((position:-webkit-sticky) or (position:sticky)) {
	.sticky-top {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}
.sr-only-focusable:active, .sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	overflow: visible;
	clip: auto;
	white-space: normal;
}
.shadow-sm {
	box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075)!important;
}
.shadow {
	box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15)!important;
}
.shadow-lg {
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175)!important;
}
.shadow-none {
	box-shadow: none!important;
}
.text-monospace {
	font-family: Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
.text-justify {
	text-align: justify!important;
}
.text-nowrap {
	white-space: nowrap!important;
}
.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.text-left {
	text-align: left!important;
}
.text-right {
	text-align: right!important;
}
.text-center {
	text-align: center!important;
}
@media (min-width:576px) {
	.text-sm-left {
		text-align: left!important;
	}
	.text-sm-right {
		text-align: right!important;
	}
	.text-sm-center {
		text-align: center!important;
	}
}
@media (min-width:768px) {
	.text-md-left {
		text-align: left!important;
	}
	.text-md-right {
		text-align: right!important;
	}
	.text-md-center {
		text-align: center!important;
	}
}
@media (min-width:992px) {
	.text-lg-left {
		text-align: left!important;
	}
	.text-lg-right {
		text-align: right!important;
	}
	.text-lg-center {
		text-align: center!important;
	}
}
@media (min-width:1280px) {
	.text-xl-left {
		text-align: left!important;
	}
	.text-xl-right {
		text-align: right!important;
	}
	.text-xl-center {
		text-align: center!important;
	}
}
.text-lowercase {
	text-transform: lowercase!important;
}
.text-uppercase {
	text-transform: uppercase!important;
}
.text-capitalize {
	text-transform: capitalize!important;
}
.font-weight-light {
	font-weight: 300!important;
}
.font-weight-normal {
	font-weight: 400!important;
}
.font-weight-semibold {
	font-weight: 500!important;
}
.font-weight-semibold1 {
	font-weight: 600!important;
}
.font-weight-bold {
	font-weight: 700!important;
}
.font-weight-extrabold {
	font-weight: 800!important;
}
.font-italic {
	font-style: italic!important;
}
.text-white {
	color: #fff!important;
}
.text-secondary {
	color: #520995!important;
}
a.text-secondary:focus, a.text-secondary:hover {
	color: #520995!important;
}
.text-success {
	color: #01d277!important;
}
a.text-success:focus, a.text-success:hover {
	color: #3adfab!important;
}
.text-info {
	color:#0b94f7!important;
}
a.text-info:focus, a.text-info:hover {
	color: #1594ef!important;
}
.text-warning {
	color: #ffa22b!important;
}
a.text-warning:focus, a.text-warning:hover {
	color: #f59713!important;
}
.text-danger {
	color: #f84242!important;
}
a.text-danger:focus, a.text-danger:hover {
	color: #f34a4a!important;
}
.text-light {
	color: #f8f9fa!important;
}
a.text-light:focus, a.text-light:hover {
	color: #e8e9f3!important;
}
.text-dark {
	color: #0a1f44!important;
}
.text-body {
	color: #3d4e67!important;
}
.text-muted {
	color: #636d8c!important;
}
.text-black-50 {
	color: rgba(0, 0, 0, .5)!important;
}
.text-white-50 {
	color: rgba(255, 255, 255, .5)!important;
}
.text-white-80 {
	color: rgba(255, 255, 255, .8)!important;
}
.text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}
.visible {
	visibility: visible!important;
}
.invisible {
	visibility: hidden!important;
}
@media print {
	*, ::after, ::before {
		text-shadow: none!important;
		box-shadow: none!important;
	}
	a:not(.btn) {
		text-decoration: underline;
	}
	abbr[title]::after {
		content: " (" attr(title) ")";
	}
	pre {
		white-space: pre-wrap!important;
	}
	blockquote, pre {
		border: 1px solid #adb5bd;
		page-break-inside: avoid;
	}
	thead {
		display: table-header-group;
	}
	img, tr {
		page-break-inside: avoid;
	}
	h2, h3, p {
		orphans: 3;
		widows: 3;
	}
	h2, h3 {
		page-break-after: avoid;
	}
	/*body {
		min-width: 992px!important;
	}*/
	.container {
		min-width: 992px!important;
	}
	.navbar {
		display: none;
	}
	.badge {
		border: 1px solid #000;
	}
	.table, .text-wrap table {
		border-collapse: collapse!important;
	}
	.table td, .table th, .text-wrap table td, .text-wrap table th {
		background-color: #fff!important;
	}
	.table-bordered td, .table-bordered th, .text-wrap table td, .text-wrap table th {
		border: 1px solid #eaebf7!important;
	}
}
.lead {
	line-height: 1.4;
}
blockquote {
	color: #2e384d;
	padding-left: 2rem;
	border-left: 3px solid rgba(227, 237, 252, .5);
}
.blockquote-reverse, blockquote.float-right {
	padding-right: 15px;
	padding-left: 0;
	text-align: right;
	border-right: 3px solid rgba(227, 237, 252, .5);
	border-left: 0;
}
blockquote p {
	margin-bottom: 1rem;
}
blockquote cite {
	display: block;
	text-align: right;
}
blockquote cite:before {
	content: '— ';
}
code {
	background: #eaebf7;
	border: 1px solid #eaebf7;
	border-radius: 4px;
	padding: 3px;
}
pre code {
	padding: 0;
	border-radius: 0;
	border: none;
	background: 0 0;
}
hr {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
}
pre {
	color: #343a40;
	padding: 1rem;
	overflow: auto;
	font-size: 85%;
	line-height: 1.45;
	background-color: #e2e4f1;
	border-radius: 4px;
	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;
	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}
img {
	max-width: 100%;
}
.text-wrap {
	font-size: 14px;
	line-height: 2;
}
.text-wrap>:first-child {
	margin-top: 0;
}
.text-wrap>:last-child {
	margin-bottom: 0;
}
.text-wrap>h1, .text-wrap>h2, .text-wrap>h3, .text-wrap>h4, .text-wrap>h5, .text-wrap>h6 {
	margin-top: 1em;
}
.section-nav {
	background-color: #f8f9fa;
	margin: 1rem 0;
	padding: .5rem 1rem;
	border: 1px solid #eaebf7;
	border-radius: 4px;
	list-style: none;
}
.section-nav:before {
	content: 'Table of contents:';
	display: block;
	font-weight: 600;
}
@media print {
	.container {
		max-width: none;
	}
}

.row-cards>.col, .row-cards>[class*=col-] {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
}
.row-deck>.col, .row-deck>[class*=col-] {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: stretch;
	align-items: stretch;
}
.row-deck>.col .card, .row-deck>[class*=col-] .card {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
}
.col-text {
	max-width: 48rem;
}
.col-login {
	max-width: 24rem;
}
.gutters-0 {
	margin-right: 0;
	margin-left: 0;
}
.gutters-0>.col, .gutters-0>[class*=col-] {
	padding-right: 0;
	padding-left: 0;
}
.gutters-0 .card {
	margin-bottom: 0;
}
.gutters-xs {
	margin-right: -.25rem;
	margin-left: -.25rem;
}
.gutters-xs>.col, .gutters-xs>[class*=col-] {
	padding-right: .25rem;
	padding-left: .25rem;
}
.gutters-xs .card {
	margin-bottom: .5rem;
}
.gutters-sm {
	margin-right: -.5rem;
	margin-left: -.5rem;
}
.gutters-sm>.col, .gutters-sm>[class*=col-] {
	padding-right: .5rem;
	padding-left: .5rem;
}
.gutters-sm .card {
	margin-bottom: 1rem;
}
.gutters-lg {
	margin-right: -1rem;
	margin-left: -1rem;
}
.gutters-lg>.col, .gutters-lg>[class*=col-] {
	padding-right: 1rem;
	padding-left: 1rem;
}
.gutters-lg .card {
	margin-bottom: 2rem;
}
.gutters-xl {
	margin-right: -1.5rem;
	margin-left: -1.5rem;
}
.gutters-xl>.col, .gutters-xl>[class*=col-] {
	padding-right: 1.5rem;
	padding-left: 1.5rem;
}
.gutters-xl .card {
	margin-bottom: 3rem;
}
.bg-blue-lightest {
	background-color: #f6f7fb!important;
}
a.bg-blue-lightest:focus, a.bg-blue-lightest:hover, button.bg-blue-lightest:focus, button.bg-blue-lightest:hover {
	background-color: #c5d5ef!important;
}
.bg-blue-lighter {
	background-color: #c8d9f1!important;
}
a.bg-blue-lighter:focus, a.bg-blue-lighter:hover, button.bg-blue-lighter:focus, button.bg-blue-lighter:hover {
	background-color: #9fbde7!important;
}
.bg-blue-light {
	background-color: #7ea5dd!important;
}
a.bg-blue-light:focus, a.bg-blue-light:hover, button.bg-blue-light:focus, button.bg-blue-light:hover {
	background-color: #5689d2!important;
}
.bg-blue-dark {
	background-color: #3866a6!important;
}
a.bg-blue-dark:focus, a.bg-blue-dark:hover, button.bg-blue-dark:focus, button.bg-blue-dark:hover {
	background-color: #2b4f80!important;
}
.bg-blue-darker {
	background-color: #1c3353!important;
}
a.bg-blue-darker:focus, a.bg-blue-darker:hover, button.bg-blue-darker:focus, button.bg-blue-darker:hover {
	background-color: #0f1c2d!important;
}
.bg-blue-darkest {
	background-color: #0e1929!important;
}
a.bg-blue-darkest:focus, a.bg-blue-darkest:hover, button.bg-blue-darkest:focus, button.bg-blue-darkest:hover {
	background-color: #010203!important;
}
.bg-purssianblue {
	background-color: #362f71;
}
a.bg-purssianblue-lightest:focus, a.bg-purssianblue-lightest:hover, button.bg-purssianblue-lightest:focus, button.bg-purssianblue-lightest:hover {
	background-color: #3f3688!important;
}
.bg-indigo-lightest {
	background-color: #f0f1fa!important;
}
a.bg-indigo-lightest:focus, a.bg-indigo-lightest:hover, button.bg-indigo-lightest:focus, button.bg-indigo-lightest:hover {
	background-color: #cacded!important;
}
.bg-indigo-lighter {
	background-color: #d1d5f0!important;
}
a.bg-indigo-lighter:focus, a.bg-indigo-lighter:hover, button.bg-indigo-lighter:focus, button.bg-indigo-lighter:hover {
	background-color: #abb2e3!important;
}
.bg-indigo-light {
	background-color: #939edc!important;
}
a.bg-indigo-light:focus, a.bg-indigo-light:hover, button.bg-indigo-light:focus, button.bg-indigo-light:hover {
	background-color: #6c7bd0!important;
}
.bg-indigo-dark {
	background-color: #515da4!important;
}
a.bg-indigo-dark:focus, a.bg-indigo-dark:hover, button.bg-indigo-dark:focus, button.bg-indigo-dark:hover {
	background-color: #404a82!important;
}
.bg-indigo-darker {
	background-color: #282e52!important;
}
a.bg-indigo-darker:focus, a.bg-indigo-darker:hover, button.bg-indigo-darker:focus, button.bg-indigo-darker:hover {
	background-color: #171b30!important;
}
.bg-indigo-darkest {
	background-color: #141729!important;
}
a.bg-indigo-darkest:focus, a.bg-indigo-darkest:hover, button.bg-indigo-darkest:focus, button.bg-indigo-darkest:hover {
	background-color: #030407!important;
}
.bg-purple-lightest {
	background-color: #f6effd!important;
}
a.bg-purple-lightest:focus, a.bg-purple-lightest:hover, button.bg-purple-lightest:focus, button.bg-purple-lightest:hover {
	background-color: #ddc2f7!important;
}
.bg-purple-lighter {
	background-color: #e4cff9!important;
}
a.bg-purple-lighter:focus, a.bg-purple-lighter:hover, button.bg-purple-lighter:focus, button.bg-purple-lighter:hover {
	background-color: #cba2f3!important;
}
.bg-purple-light {
	background-color: #c08ef0!important;
}
a.bg-purple-light:focus, a.bg-purple-light:hover, button.bg-purple-light:focus, button.bg-purple-light:hover {
	background-color: #a761ea!important;
}
.bg-purple-dark {
	background-color: #844bbb!important;
}
a.bg-purple-dark:focus, a.bg-purple-dark:hover, button.bg-purple-dark:focus, button.bg-purple-dark:hover {
	background-color: #6a3a99!important;
}
.bg-purple-darker {
	background-color: #42265e!important;
}
a.bg-purple-darker:focus, a.bg-purple-darker:hover, button.bg-purple-darker:focus, button.bg-purple-darker:hover {
	background-color: #29173a!important;
}
.bg-purple-darkest {
	background-color: #21132f!important;
}
a.bg-purple-darkest:focus, a.bg-purple-darkest:hover, button.bg-purple-darkest:focus, button.bg-purple-darkest:hover {
	background-color: #08040b!important;
}
.bg-pink-lightest {
	background-color: #fef0f5!important;
}
a.bg-pink-lightest:focus, a.bg-pink-lightest:hover, button.bg-pink-lightest:focus, button.bg-pink-lightest:hover {
	background-color: #fbc0d5!important;
}
.bg-pink-lighter {
	background-color: #fcd3e1!important;
}
a.bg-pink-lighter:focus, a.bg-pink-lighter:hover, button.bg-pink-lighter:focus, button.bg-pink-lighter:hover {
	background-color: #f9a3c0!important;
}
.bg-pink-light {
	background-color: #f999b9!important;
}
a.bg-pink-light:focus, a.bg-pink-light:hover, button.bg-pink-light:focus, button.bg-pink-light:hover {
	background-color: #f66998!important;
}
.bg-pink-dark {
	background-color: #c5577c!important;
}
a.bg-pink-dark:focus, a.bg-pink-dark:hover, button.bg-pink-dark:focus, button.bg-pink-dark:hover {
	background-color: #ad3c62!important;
}
.bg-pink-darker {
	background-color: #622c3e!important;
}
a.bg-pink-darker:focus, a.bg-pink-darker:hover, button.bg-pink-darker:focus, button.bg-pink-darker:hover {
	background-color: #3f1c28!important;
}
.bg-pink-darkest {
	background-color: #31161f!important;
}
a.bg-pink-darkest:focus, a.bg-pink-darkest:hover, button.bg-pink-darkest:focus, button.bg-pink-darkest:hover {
	background-color: #0e0609!important;
}
.bg-red-lightest {
	background-color: #fae9e9!important;
}
a.bg-red-lightest:focus, a.bg-red-lightest:hover, button.bg-red-lightest:focus, button.bg-red-lightest:hover {
	background-color: #f1bfbf!important;
}
.bg-red-lighter {
	background-color: #f0bcbc!important;
}
a.bg-red-lighter:focus, a.bg-red-lighter:hover, button.bg-red-lighter:focus, button.bg-red-lighter:hover {
	background-color: #e79292!important;
}
.bg-red-light {
	background-color: #dc6362!important;
}
a.bg-red-light:focus, a.bg-red-light:hover, button.bg-red-light:focus, button.bg-red-light:hover {
	background-color: #d33a38!important;
}
.bg-red-dark {
	background-color: #a41a19!important;
}
a.bg-red-dark:focus, a.bg-red-dark:hover, button.bg-red-dark:focus, button.bg-red-dark:hover {
	background-color: #781312!important;
}
.bg-red-darker {
	background-color: #520d0c!important;
}
a.bg-red-darker:focus, a.bg-red-darker:hover, button.bg-red-darker:focus, button.bg-red-darker:hover {
	background-color: #260605!important;
}
.bg-red-darkest {
	background-color: #290606!important;
}
a.bg-red-darkest:focus, a.bg-red-darkest:hover, button.bg-red-darkest:focus, button.bg-red-darkest:hover {
	background-color: #000!important;
}
.bg-orange-lightest {
	background-color: #fff5ec!important;
}
a.bg-orange-lightest:focus, a.bg-orange-lightest:hover, button.bg-orange-lightest:focus, button.bg-orange-lightest:hover {
	background-color: #ffdab9!important;
}
.bg-orange-lighter {
	background-color: #fee0c7!important;
}
a.bg-orange-lighter:focus, a.bg-orange-lighter:hover, button.bg-orange-lighter:focus, button.bg-orange-lighter:hover {
	background-color: #fdc495!important;
}
.bg-orange-light {
	background-color: #feb67c!important;
}
a.bg-orange-light:focus, a.bg-orange-light:hover, button.bg-orange-light:focus, button.bg-orange-light:hover {
	background-color: #fe9a49!important;
}
.bg-orange-dark {
	background-color: #ca7836!important;
}
a.bg-orange-dark:focus, a.bg-orange-dark:hover, button.bg-orange-dark:focus, button.bg-orange-dark:hover {
	background-color: #a2602b!important;
}
.bg-orange-darker {
	background-color: #653c1b!important;
}
a.bg-orange-darker:focus, a.bg-orange-darker:hover, button.bg-orange-darker:focus, button.bg-orange-darker:hover {
	background-color: #3d2410!important;
}
.bg-orange-darkest {
	background-color: #331e0e!important;
}
a.bg-orange-darkest:focus, a.bg-orange-darkest:hover, button.bg-orange-darkest:focus, button.bg-orange-darkest:hover {
	background-color: #0b0603!important;
}
.bg-yellow-lightest {
	background-color: #fef9e7!important;
}
a.bg-yellow-lightest:focus, a.bg-yellow-lightest:hover, button.bg-yellow-lightest:focus, button.bg-yellow-lightest:hover {
	background-color: #fcedb6!important;
}
.bg-yellow-lighter {
	background-color: #fbedb7!important;
}
a.bg-yellow-lighter:focus, a.bg-yellow-lighter:hover, button.bg-yellow-lighter:focus, button.bg-yellow-lighter:hover {
	background-color: #f8e187!important;
}
.bg-yellow-light {
	background-color: #f5d657!important;
}
a.bg-yellow-light:focus, a.bg-yellow-light:hover, button.bg-yellow-light:focus, button.bg-yellow-light:hover {
	background-color: #f2ca27!important;
}
.bg-yellow-dark {
	background-color: #c19d0c!important;
}
a.bg-yellow-dark:focus, a.bg-yellow-dark:hover, button.bg-yellow-dark:focus, button.bg-yellow-dark:hover {
	background-color: #917609!important;
}
.bg-yellow-darker {
	background-color: #604e06!important;
}
a.bg-yellow-darker:focus, a.bg-yellow-darker:hover, button.bg-yellow-darker:focus, button.bg-yellow-darker:hover {
	background-color: #302703!important;
}
.bg-yellow-darkest {
	background-color: #302703!important;
}
a.bg-yellow-darkest:focus, a.bg-yellow-darkest:hover, button.bg-yellow-darkest:focus, button.bg-yellow-darkest:hover {
	background-color: #000!important;
}
.bg-green-lightest {
	background-color: #eff8e6!important;
}
a.bg-green-lightest:focus, a.bg-green-lightest:hover, button.bg-green-lightest:focus, button.bg-green-lightest:hover {
	background-color: #d6edbe!important;
}
.bg-green-lighter {
	background-color: #cfeab3!important;
}
a.bg-green-lighter:focus, a.bg-green-lighter:hover, button.bg-green-lighter:focus, button.bg-green-lighter:hover {
	background-color: #b6df8b!important;
}
.bg-green-light {
	background-color: #8ecf4d!important;
}
a.bg-green-light:focus, a.bg-green-light:hover, button.bg-green-light:focus, button.bg-green-light:hover {
	background-color: #75b831!important;
}
.bg-green-dark {
	background-color: #4b9500!important;
}
a.bg-green-dark:focus, a.bg-green-dark:hover, button.bg-green-dark:focus, button.bg-green-dark:hover {
	background-color: #316200!important;
}
.bg-green-darker {
	background-color: #264a00!important;
}
a.bg-green-darker:focus, a.bg-green-darker:hover, button.bg-green-darker:focus, button.bg-green-darker:hover {
	background-color: #0c1700!important;
}
.bg-green-darkest {
	background-color: #132500!important;
}
a.bg-green-darkest:focus, a.bg-green-darkest:hover, button.bg-green-darkest:focus, button.bg-green-darkest:hover {
	background-color: #000!important;
}
.bg-teal-lightest {
	background-color: #eafaf8!important;
}
a.bg-teal-lightest:focus, a.bg-teal-lightest:hover, button.bg-teal-lightest:focus, button.bg-teal-lightest:hover {
	background-color: #c1f0ea!important;
}
.bg-teal-lighter {
	background-color: #bfefea!important;
}
a.bg-teal-lighter:focus, a.bg-teal-lighter:hover, button.bg-teal-lighter:focus, button.bg-teal-lighter:hover {
	background-color: #96e5dd!important;
}
.bg-teal-light {
	background-color: #6bdbcf!important;
}
a.bg-teal-light:focus, a.bg-teal-light:hover, button.bg-teal-light:focus, button.bg-teal-light:hover {
	background-color: #42d1c2!important;
}
.bg-teal-dark {
	background-color: #22a295!important;
}
a.bg-teal-dark:focus, a.bg-teal-dark:hover, button.bg-teal-dark:focus, button.bg-teal-dark:hover {
	background-color: #19786e!important;
}
.bg-teal-darker {
	background-color: #11514a!important;
}
a.bg-teal-darker:focus, a.bg-teal-darker:hover, button.bg-teal-darker:focus, button.bg-teal-darker:hover {
	background-color: #082723!important;
}
.bg-teal-darkest {
	background-color: #092925!important;
}
a.bg-teal-darkest:focus, a.bg-teal-darkest:hover, button.bg-teal-darkest:focus, button.bg-teal-darkest:hover {
	background-color: #000!important;
}
.bg-cyan-lightest {
	background-color: #e8f6f8!important;
}
a.bg-cyan-lightest:focus, a.bg-cyan-lightest:hover, button.bg-cyan-lightest:focus, button.bg-cyan-lightest:hover {
	background-color: #c1e7ec!important;
}
.bg-cyan-lighter {
	background-color: #b9e3ea!important;
}
a.bg-cyan-lighter:focus, a.bg-cyan-lighter:hover, button.bg-cyan-lighter:focus, button.bg-cyan-lighter:hover {
	background-color: #92d3de!important;
}
.bg-cyan-light {
	background-color: #5dbecd!important;
}
a.bg-cyan-light:focus, a.bg-cyan-light:hover, button.bg-cyan-light:focus, button.bg-cyan-light:hover {
	background-color: #3aabbd!important;
}
.bg-cyan-dark {
	background-color: #128293!important;
}
a.bg-cyan-dark:focus, a.bg-cyan-dark:hover, button.bg-cyan-dark:focus, button.bg-cyan-dark:hover {
	background-color: #0c5a66!important;
}
.bg-cyan-darker {
	background-color: #09414a!important;
}
a.bg-cyan-darker:focus, a.bg-cyan-darker:hover, button.bg-cyan-darker:focus, button.bg-cyan-darker:hover {
	background-color: #03191d!important;
}
.bg-cyan-darkest {
	background-color: #052025!important;
}
a.bg-cyan-darkest:focus, a.bg-cyan-darkest:hover, button.bg-cyan-darkest:focus, button.bg-cyan-darkest:hover {
	background-color: #000!important;
}
.bg-white-lightest {
	background-color: #fff!important;
}
a.bg-white-lightest:focus, a.bg-white-lightest:hover, button.bg-white-lightest:focus, button.bg-white-lightest:hover {
	background-color: #e6e5e5!important;
}
.bg-white-lighter {
	background-color: #fff!important;
}
a.bg-white-lighter:focus, a.bg-white-lighter:hover, button.bg-white-lighter:focus, button.bg-white-lighter:hover {
	background-color: #e6e5e5!important;
}
.bg-white-light {
	background-color: #fff!important;
}
a.bg-white-light:focus, a.bg-white-light:hover, button.bg-white-light:focus, button.bg-white-light:hover {
	background-color: #e6e5e5!important;
}
.bg-white-dark {
	background-color: #ccc!important;
}
a.bg-white-dark:focus, a.bg-white-dark:hover, button.bg-white-dark:focus, button.bg-white-dark:hover {
	background-color: #b3b2b2!important;
}
.bg-white-darker {
	background-color: #666!important;
}
a.bg-white-darker:focus, a.bg-white-darker:hover, button.bg-white-darker:focus, button.bg-white-darker:hover {
	background-color: #4d4c4c!important;
}
.bg-white-darkest {
	background-color: #333!important;
}
a.bg-white-darkest:focus, a.bg-white-darkest:hover, button.bg-white-darkest:focus, button.bg-white-darkest:hover {
	background-color: #1a1919!important;
}
.bg-gray-lightest {
	background-color: #f3f4f5!important;
}
a.bg-gray-lightest:focus, a.bg-gray-lightest:hover, button.bg-gray-lightest:focus, button.bg-gray-lightest:hover {
	background-color: #d7dbde!important;
}
.bg-gray-lighter {
	background-color: #dbdde0!important;
}
a.bg-gray-lighter:focus, a.bg-gray-lighter:hover, button.bg-gray-lighter:focus, button.bg-gray-lighter:hover {
	background-color: #c0c3c8!important;
}
.bg-gray-light {
	background-color: #aab0b6!important;
}
a.bg-gray-light:focus, a.bg-gray-light:hover, button.bg-gray-light:focus, button.bg-gray-light:hover {
	background-color: #8f979e!important;
}
.bg-gray-dark {
	background-color: #6b7278!important;
}
a.bg-gray-dark:focus, a.bg-gray-dark:hover, button.bg-gray-dark:focus, button.bg-gray-dark:hover {
	background-color: #53585d!important;
}
.bg-gray-darker {
	background-color: #36393c!important;
}
a.bg-gray-darker:focus, a.bg-gray-darker:hover, button.bg-gray-darker:focus, button.bg-gray-darker:hover {
	background-color: #1e2021!important;
}
.bg-gray-darkest {
	background-color: #1b1c1e!important;
}
a.bg-gray-darkest:focus, a.bg-gray-darkest:hover, button.bg-gray-darkest:focus, button.bg-gray-darkest:hover {
	background-color: #030303!important;
}
.bg-gray-dark-lightest {
	background-color: #ebebec!important;
}
a.bg-gray-dark-lightest:focus, a.bg-gray-dark-lightest:hover, button.bg-gray-dark-lightest:focus, button.bg-gray-dark-lightest:hover {
	background-color: #d1d1d3!important;
}
.bg-gray-dark-lighter {
	background-color: #c2c4c6!important;
}
a.bg-gray-dark-lighter:focus, a.bg-gray-dark-lighter:hover, button.bg-gray-dark-lighter:focus, button.bg-gray-dark-lighter:hover {
	background-color: #a8abad!important;
}
.bg-gray-dark-light {
	background-color: #717579!important;
}
a.bg-gray-dark-light:focus, a.bg-gray-dark-light:hover, button.bg-gray-dark-light:focus, button.bg-gray-dark-light:hover {
	background-color: #585c5f!important;
}
.bg-gray-dark-dark {
	background-color: #2a2e33!important;
}
a.bg-gray-dark-dark:focus, a.bg-gray-dark-dark:hover, button.bg-gray-dark-dark:focus, button.bg-gray-dark-dark:hover {
	background-color: #131517!important;
}
.bg-gray-dark-darker {
	background-color: #15171a!important;
}
a.bg-gray-dark-darker:focus, a.bg-gray-dark-darker:hover, button.bg-gray-dark-darker:focus, button.bg-gray-dark-darker:hover {
	background-color: #000!important;
}
.bg-gray-dark-darkest {
	background-color: #0a0c0d!important;
}
a.bg-gray-dark-darkest:focus, a.bg-gray-dark-darkest:hover, button.bg-gray-dark-darkest:focus, button.bg-gray-dark-darkest:hover {
	background-color: #000!important;
}
.bg-azure-lightest {
	background-color: #ecf7fe!important;
}
a.bg-azure-lightest:focus, a.bg-azure-lightest:hover, button.bg-azure-lightest:focus, button.bg-azure-lightest:hover {
	background-color: #bce3fb!important;
}
.bg-azure-lighter {
	background-color: #c7e6fb!important;
}
a.bg-azure-lighter:focus, a.bg-azure-lighter:hover, button.bg-azure-lighter:focus, button.bg-azure-lighter:hover {
	background-color: #97d1f8!important;
}
.bg-azure-light {
	background-color: #7dc4f6!important;
}
a.bg-azure-light:focus, a.bg-azure-light:hover, button.bg-azure-light:focus, button.bg-azure-light:hover {
	background-color: #4daef3!important;
}
.bg-azure-dark {
	background-color: #3788c2!important;
}
a.bg-azure-dark:focus, a.bg-azure-dark:hover, button.bg-azure-dark:focus, button.bg-azure-dark:hover {
	background-color: #2c6c9a!important;
}
.bg-azure-darker {
	background-color: #1c4461!important;
}
a.bg-azure-darker:focus, a.bg-azure-darker:hover, button.bg-azure-darker:focus, button.bg-azure-darker:hover {
	background-color: #112839!important;
}
.bg-azure-darkest {
	background-color: #0e2230!important;
}
a.bg-azure-darkest:focus, a.bg-azure-darkest:hover, button.bg-azure-darkest:focus, button.bg-azure-darkest:hover {
	background-color: #020609!important;
}
.bg-lime-lightest {
	background-color: #f2fbeb!important;
}
a.bg-lime-lightest:focus, a.bg-lime-lightest:hover, button.bg-lime-lightest:focus, button.bg-lime-lightest:hover {
	background-color: #d6f3c1!important;
}
.bg-lime-lighter {
	background-color: #d7f2c2!important;
}
a.bg-lime-lighter:focus, a.bg-lime-lighter:hover, button.bg-lime-lighter:focus, button.bg-lime-lighter:hover {
	background-color: #bbe998!important;
}
.bg-lime-light {
	background-color: #a3e072!important;
}
a.bg-lime-light:focus, a.bg-lime-light:hover, button.bg-lime-light:focus, button.bg-lime-light:hover {
	background-color: #88d748!important;
}
.bg-lime-dark {
	background-color: #62a82a!important;
}
a.bg-lime-dark:focus, a.bg-lime-dark:hover, button.bg-lime-dark:focus, button.bg-lime-dark:hover {
	background-color: #4a7f20!important;
}
.bg-lime-darker {
	background-color: #315415!important;
}
a.bg-lime-darker:focus, a.bg-lime-darker:hover, button.bg-lime-darker:focus, button.bg-lime-darker:hover {
	background-color: #192b0b!important;
}
.bg-lime-darkest {
	background-color: #192a0b!important;
}
a.bg-lime-darkest:focus, a.bg-lime-darkest:hover, button.bg-lime-darkest:focus, button.bg-lime-darkest:hover {
	background-color: #010200!important;
}
.display-1 i, .display-2 i, .display-3 i, .display-4 i {
	vertical-align: baseline;
	font-size: .815em;
}
.text-inherit {
	color: inherit!important;
}
.text-default {
	color: #4e5d78 !important;
}
.text-muted-dark {
	color: #5f6877!important;
}
.tracking-tight {
	letter-spacing: -.05em!important;
}
.tracking-normal {
	letter-spacing: 0!important;
}
.tracking-wide {
	letter-spacing: .05em!important;
}
.leading-none {
	line-height: 1!important;
}
.leading-tight {
	line-height: 1.3!important;
}
.leading-normal {
	line-height: 1.5!important;
}
.leading-loose {
	line-height: 2!important;
}
.bg-blue {
	background-color: #4049ec !important;
	color: #fff!important;
}
a.bg-blue:focus, a.bg-blue:hover, button.bg-blue:focus, button.bg-blue:hover {
	background-color: #4a52ec !important;
}
.text-blue {
	color: #4049ec !important;
}
.bg-indigo {
	background-color: #6574cd!important;
	color: #fff!important;
}
a.bg-indigo:focus, a.bg-indigo:hover, button.bg-indigo:focus, button.bg-indigo:hover {
	background-color: #3f51c1!important;
}
.text-indigo {
	color: #6574cd!important;
}
.bg-purple {
	background-color: #8757ff  !important;
	color: #fff!important;
}
a.bg-purple:focus, a.bg-purple:hover, button.bg-purple:focus, button.bg-purple:hover {
	background-color: #8c31e4!important;
}
.text-purple {
	color: #6d33ff!important;
}
.text-lightpink-red {
	color: #ff7088!important;
}
.text-lightgreen {
	color: #26eda2!important;
}
.bg-pink {
	background-color: #f5127b!important;
	color: #fff!important;
}
.bg-darkpink {
	background-color: #fc0f79!important;
	color: #fff!important;
}
a.bg-pink:focus, a.bg-pink:hover, button.bg-pink:focus, button.bg-pink:hover {
	background-color: #ed3284!important;
}
.text-pink {
	color: #f5127b!important;
}
.bg-red {
	background-color: #f84242!important;
	color: #fff!important;
}
a.bg-red:focus, a.bg-red:hover, button.bg-red:focus, button.bg-red:hover {
	background-color: #f34a4a!important;
}
.text-red {
	color: #f84242!important;
}
.bg-orange {
	background-color: #e67605!important;
	color: #fff!important;
}
a.bg-orange:focus, a.bg-orange:hover, button.bg-orange:focus, button.bg-orange:hover {
	background-color: #fc7a12!important;
}
.text-orange {
	color: #e67605!important;
}
.bg-yellow {
	background-color: #ffa22b!important;
	color: #fff!important;
}
a.bg-yellow:focus, a.bg-yellow:hover, button.bg-yellow:focus, button.bg-yellow:hover {
	background-color: #f59713!important;
}
.text-yellow {
	color: #ffa22b!important;
}
.bg-green {
	background-color: #01d277!important;
	color: #000!important;
}
a.bg-green:focus, a.bg-green:hover, button.bg-green:focus, button.bg-green:hover {
	background-color: #3adfab!important;
}
.text-green {
	color: #01d277!important;
}
.bg-teal {
	background-color: #05e6e6!important;
}
a.bg-teal:focus, a.bg-teal:hover, button.bg-teal:focus, button.bg-teal:hover {
	background-color: #05fafa!important;
}
.text-teal {
	color: #05e6e6!important;
}
.bg-cyan {
	background-color: #17a2b8!important;
	color: #fff!important;
}
a.bg-cyan:focus, a.bg-cyan:hover, button.bg-cyan:focus, button.bg-cyan:hover {
	background-color: #117a8b!important;
}
.text-cyan {
	color: #17a2b8!important;
}
.bg-transparent {
	background: 0 0;
}
.bg-white {
	background-color: #fff!important;
}
a.bg-white:focus, a.bg-white:hover, button.bg-white:focus, button.bg-white:hover {
	background-color: #e6e5e5!important;
}
.text-white {
	color: #fff!important;
}
.bg-gray {
	background-color: #868e96!important;
}
a.bg-gray:focus, a.bg-gray:hover, button.bg-gray:focus, button.bg-gray:hover {
	background-color: #2e384d!important;
}
.text-gray {
	color: #858d97!important;
}
.bg-gray-dark {
	background-color: #40444a !important;
}
.bg-lightpink-red {
	color: #ff7088!important;
}
a.bg-gray-dark:focus, a.bg-gray-dark:hover, button.bg-gray-dark:focus, button.bg-gray-dark:hover {
	background-color: #1d2124!important;
}
.text-gray-dark {
	color: #343a40!important;
}
.bg-azure {
	background-color:#0b94f7!important;
}
a.bg-azure:focus, a.bg-azure:hover, button.bg-azure:focus, button.bg-azure:hover {
	background-color: #1594ef!important;
}
.text-azure {
	color:#0b94f7!important;
}
.bg-lime {
	background-color: #63e600!important;
}
a.bg-lime:focus, a.bg-lime:hover, button.bg-lime:focus, button.bg-lime:hover {
	background-color: #63ad27!important;
}
.text-lime {
	color: #63e600!important;
}
.bg-purple1 {
	background: #6e26a6;
}
.icon i {
	vertical-align: -1px;
}
a.icon {
	text-decoration: none;
	cursor: pointer;
}
a.icon:hover {
	color: #1a1a1a!important;
}
.o-auto {
	overflow: auto!important;
}
.o-hidden {
	overflow: hidden!important;
}
.shadow {
	box-shadow: 0 3px 18px 0 rgba(0, 0, 0, .05)!important;
}
.shadow-none {
	box-shadow: none!important;
}

.bg-success-transparent {
	background-color: rgba(33, 182, 50, 0.13);
}
.bg-info-transparent {
	background-color: rgba(11, 148, 247, .1);
}
.bg-warning-transparent {
	background-color:  rgba(249, 167, 63, 0.1);
}
.bg-danger-transparent {
	background-color: rgba(255, 56, 43, .1);
}
.bg-pink-transparent {
	background-color: rgba(255, 43, 136, .1);
}
.bg-purple-transparent {
	background-color: rgba(96, 77, 216, .1);
}
.bg-dark-transparent {
	background-color: rgba(0, 0, 0, .3);
}
.bg-dark-transparent2 {
	background-color: rgba(0, 0, 0, .2);
}
.bg-dark-transparent1 {
	background-color: rgba(0, 0, 0, .1);
}
.bg-dark-transparent01{
	background-color: rgba(0, 0, 0, .05);
}
.box-shadow {
	box-shadow: 0px 0px 20px rgba(126,142,177, .5)!important;
}