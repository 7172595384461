/*------------------------------------------------------------------
[Ribbpn Stylesheet]

Project        :   Templist - HTML5 Premium Digital goods marketplace directory jquery css responsive website Template
Version        :   V.1
Create Date    :   28/02/20
Copyright      :   Spruko Technologies Private Limited 
Author         :   SprukoSoft
Author URL     :   https://themeforest.net/user/sprukosoft
Support		   :   support@spruko.com
License        :   Licensed under ThemeForest License

-------------------------------------------------------------------*/

/** Riboons
/** arrow Ribbon
/** Badge Styles
/** Ribbon1
/** Ribbon2
/** Ribbon3

/*--Riboons--*/
.ribbone {
	width: 100%;
	position: relative;
	background-size: cover;
	text-transform: uppercase;
	color: #fff;
}
@media (max-width:500px) {
	.ribbone {
		width: 100%;
	}
}
.ribbon1 {
	position: absolute;
	top: -6.1px;
	left: 10px;
	z-index: 1;
}
.ribbon1:after {
	position: absolute;
	content: "";
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 24px solid transparent;
	border-top: 13px solid #f8463f;
}
.ribbon1 span {
	position: relative;
	display: block;
	text-align: center;
	background: #f8463f;
	font-size: 14px;
	line-height: 1;
	padding: 12px 8px 10px;
	border-top-right-radius: 8px;
}
.ribbon1 span:after, .ribbon1 span:before {
	position: absolute;
	content: "";
}
.ribbon1 span:before {
	height: 6px;
	width: 6px;
	left: -6px;
	top: 0;
	background: #f8463f;
}
.ribbon1 span:after {
	height: 6px;
	width: 8px;
	left: -8px;
	top: 0;
	border-radius: 8px 8px 0 0;
	background: #c02031;
}

/*--arrow Ribbon--*/
.arrow-ribbon {
	color: #fff;
	padding: 2px 8px;
	position: absolute;
	top: 10px;
	left: 0;
	z-index: 98;
	font-size: 18px;
}
.arrow-ribbon.bg-purple:before {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	content: "";
	right: -15px;
	border-top: 15px solid transparent;
	border-left: 15px solid #6d33ff;
	border-bottom: 15px solid transparent;
	width: 0;
}
.arrow-ribbon.bg-danger:before {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	content: "";
	right: -15px;
	border-top: 15px solid transparent;
	border-left: 15px solid #f84242;
	border-bottom: 15px solid transparent;
	width: 0;
}
.arrow-ribbon.bg-primary:before {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	content: "";
	right: -15px;
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
	width: 0;
}
.arrow-ribbon.bg-secondary:before {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	content: "";
	right: -15px;
	border-top: 15px solid transparent;
	border-left: 15px solid #520995;
	border-bottom: 15px solid transparent;
	width: 0;
}
.arrow-ribbon.bg-success:before {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	content: "";
	right: -15px;
	border-top: 15px solid transparent;
	border-left: 15px solid #09d37b;
	border-bottom: 15px solid transparent;
	width: 0;
}
.arrow-ribbon.bg-info:before {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	content: "";
	right: -15px;
	border-top: 15px solid transparent;
	border-left: 15px solid#0b94f7;
	border-bottom: 15px solid transparent;
	width: 0;
}
.arrow-ribbon.bg-warning:before {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	content: "";
	right: -15px;
	border-top: 15px solid transparent;
	border-left: 15px solid #ffa22b;
	border-bottom: 15px solid transparent;
	width: 0;
}
.arrow-ribbon.bg-pink:before {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	content: "";
	right: -15px;
	border-top: 15px solid transparent;
	border-left: 15px solid #f5127b;
	border-bottom: 15px solid transparent;
	width: 0;
}
.arrow-ribbon2 {
	color: #fff;
	padding: 3px 8px;
	position: absolute;
	top: 10px;
	left: -1px;
	z-index: 98;
	font-size: 30px;
}
.arrow-ribbon2:before {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	content: "";
	right: -24px;
	border-top: 24px solid transparent;
	border-bottom: 24px solid transparent;
	width: 0;
}
.ribbon {
	width: 150px;
	height: 150px;
	overflow: hidden;
	position: absolute;
	z-index: 10;
}
.power-ribbon {
	width: 56px;
	height: 56px;
	overflow: hidden;
	position: absolute;
	z-index: 1;
}
.power-ribbon span {
	position: absolute;
	display: block;
	width: 82px;
	padding: 8px 0;
	color: #fff;
	font: 500 16px/1 'Roboto', sans-serif;
	text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
	text-transform: capitalize;
	text-align: center;
}
.power-ribbon-top-left span {
	right: -5px;
	top: 1px;
	transform: rotate(-45deg);
}
.power-ribbon-top-left span i {
	transform: rotate(45deg);
	padding-top: 2px;
	padding-left: 7px;
}
.power-ribbon-top-left {
	top: -6px;
	left: -9px;
}
.ribbon::after, .ribbon::before {
	position: absolute;
	z-index: -1;
	content: '';
	display: block;
	border: 5px solid;
}
.ribbon span {
	position: absolute;
	display: block;
	width: 225px;
	padding: 8px 0;
	box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
	color: #fff;
	text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
	text-transform: capitalize;
	text-align: center;
}
.ribbon-top-left {
	top: -10px;
	left: -10px;
}
.ribbon-top-left::after, .ribbon-top-left::before {
	border-top-color: transparent;
	border-left-color: transparent;
}
.ribbon-top-left::before {
	top: 0;
	right: 50px;
}
.ribbon-top-left::after {
	bottom: 50px;
	left: 0;
}
.ribbon-top-left span {
	right: -8px;
	top: 30px;
	transform: rotate(-45deg);
}
.ribbon-top-right {
	top: -10px;
	right: -10px;
}
.ribbon-top-right::after, .ribbon-top-right::before {
	border-top-color: transparent;
	border-right-color: transparent;
}
.ribbon-top-right::before {
	top: 0;
	left: 36px;
}
.ribbon-top-right::after {
	bottom: 36px;
	right: 0;
}
.ribbon-top-right span {
	left: -8px;
	top: 30px;
	transform: rotate(45deg);
}
.power-ribbon-top-right span {
    left: -5px;
    top: 1px;
    transform: rotate(45deg);
}
.power-ribbon-top-right span i {
	transform: rotate(-45deg);
	padding-top: 2px;
	padding-right: 7px;
}
.power-ribbon-top-right {
	top: -6px;
	right: -9px;
}
.power-ribbon-top-right span {
	left: -5px;
	top: 1px;
	transform: rotate(45deg);
}

/*--Badge Styles--*/
.badge-offer {
	position: absolute;
	top: 0;
	left: 15px;
	z-index: 2;
	padding: 5px;
	font-weight: 400;
	text-align: center;
	color: #fff;
	font-size: 16px;
	height: 33px;
	width: 50px;
	margin-bottom: 0;
}
.badge-offer.bg-danger:after {
	border-top: 12px solid #f84242;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	content: "";
	height: 0;
	left: 0;
	position: absolute;
	top: 33px;
	width: 0;
}
.badge-offer.bg-primary:after {
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	content: "";
	height: 0;
	left: 0;
	position: absolute;
	top: 33px;
	width: 0;
}
.badge-offer.bg-secondary:after {
	border-top: 12px solid #f6f7fb;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	content: "";
	height: 0;
	left: 0;
	position: absolute;
	top: 33px;
	width: 0;
}
.badge-offer.bg-success:after {
	border-top: 12px solid #01d277;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	content: "";
	height: 0;
	left: 0;
	position: absolute;
	top: 33px;
	width: 0;
}
.badge-offer.bg-info:after {
	border-top: 12px solid#0b94f7;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	content: "";
	height: 0;
	left: 0;
	position: absolute;
	top: 33px;
	width: 0;
}
.badge-offer.bg-warning:after {
	border-top: 12px solid #ffa22b;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	content: "";
	height: 0;
	left: 0;
	position: absolute;
	top: 33px;
	width: 0;
}
.badge-offer.bg-purple:after {
	border-top: 12px solid #6d33ff;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	content: "";
	height: 0;
	left: 0;
	position: absolute;
	top: 33px;
	width: 0;
}
.badge-offer.bg-pink:after {
	border-top: 12px solid #f5127b;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	content: "";
	height: 0;
	left: 0;
	position: absolute;
	top: 33px;
	width: 0;
}
.badge-offer1 {
	position: absolute;
	top: 0;
	right: 15px;
	z-index: 2;
	padding: 5px;
	font-weight: 400;
	text-align: center;
	color: #fff;
	font-size: 16px;
	height: 33px;
	width: 50px;
	margin-bottom: 0;
}
.badge-offer1.bg-danger:after {
	border-top: 12px solid #f84242;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	content: "";
	height: 0;
	left: 0;
	position: absolute;
	top: 33px;
	width: 0;
}
.badge-offer1.bg-primary:after {
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	content: "";
	height: 0;
	left: 0;
	position: absolute;
	top: 33px;
	width: 0;
}
.badge-offer1.bg-secondary:after {
	border-top: 12px solid #f6f7fb;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	content: "";
	height: 0;
	left: 0;
	position: absolute;
	top: 33px;
	width: 0;
}
.badge-offer1.bg-success:after {
	border-top: 12px solid #01d277;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	content: "";
	height: 0;
	left: 0;
	position: absolute;
	top: 33px;
	width: 0;
}
.badge-offer1.bg-info:after {
	border-top: 12px solid#0b94f7;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	content: "";
	height: 0;
	left: 0;
	position: absolute;
	top: 33px;
	width: 0;
}
.badge-offer1.bg-warning:after {
	border-top: 12px solid #ffa22b;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	content: "";
	height: 0;
	left: 0;
	position: absolute;
	top: 33px;
	width: 0;
}
.badge-offer1.bg-purple:after {
	border-top: 12px solid #6d33ff;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	content: "";
	height: 0;
	left: 0;
	position: absolute;
	top: 33px;
	width: 0;
}
.badge-offer1.bg-pink:after {
	border-top: 12px solid #f5127b;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	content: "";
	height: 0;
	left: 0;
	position: absolute;
	top: 33px;
	width: 0;
}

/*--Ribbon1--*/
.ribbon-1 {
	position: absolute;
	top: -6.1px;
	right: 10px;
	color: #fff;
}
.ribbon-1:after {
	position: absolute;
	content: "";
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 24px solid transparent;
	z-index: 99;
}
.ribbon-1 span {
	position: relative;
	display: block;
	text-align: center;
	font-size: 14px;
	line-height: 1;
	padding: 12px 8px 10px;
	border-top-right-radius: 8px;
	z-index: 99;
}
.ribbon-1 span:after, .ribbon-1 span:before {
	position: absolute;
	content: "";
}
.ribbon-1 span:before {
	height: 6px;
	width: 6px;
	left: -6px;
	top: 0;
}
.ribbon-1 span:after {
	height: 6px;
	width: 8px;
	left: -8px;
	top: 0;
	border-radius: 8px 8px 0 0;
}
.ribbon-1 i {
	color: #fff;
	font-size: 16px;
	padding-left: 7px;
	padding-right: 7px;
}

/*--Ribbon2--*/
.ribbon-2 {
	position: absolute;
	top: -6.1px;
	right: 10px;
	color: #fff;
}
.ribbon-2:after {
	position: absolute;
	content: "";
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 24px solid transparent;
	border-top: 13px solid #520995;
	z-index: 99;
}
.ribbon-2 span {
	position: relative;
	display: block;
	text-align: center;
	background: #520995;
	font-size: 14px;
	line-height: 1;
	padding: 12px 8px 10px;
	border-top-right-radius: 8px;
	z-index: 99;
}
.ribbon-2 span:after, .ribbon-2 span:before {
	position: absolute;
	content: "";
}
.ribbon-2 span:before {
	height: 6px;
	width: 6px;
	left: -6px;
	top: 0;
	background: #520995;
}
.ribbon-2 span:after {
	height: 6px;
	width: 8px;
	left: -8px;
	top: 0;
	border-radius: 8px 8px 0 0;
	background: #520995;
}
.ribbon-2 i {
	color: #fff;
	font-size: 16px;
	padding-left: 7px;
	padding-right: 7px;
}

/*--Ribbon3--*/
.ribbon-3 {
	position: absolute;
	top: -6.1px;
	right: 10px;
	color: #fff;
}
.ribbon-3:after {
	position: absolute;
	content: "";
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 24px solid transparent;
	border-top: 13px solid #0ccad8;
	z-index: 99;
}
.ribbon-3 span {
	position: relative;
	display: block;
	text-align: center;
	background: #0ccad8;
	font-size: 14px;
	line-height: 1;
	padding: 12px 8px 10px;
	border-top-right-radius: 8px;
	z-index: 99;
}
.ribbon-1 span:after, .ribbon-3 span:before {
	position: absolute;
	content: "";
}
.ribbon-3 span:before {
	height: 6px;
	width: 6px;
	left: -6px;
	top: 0;
	background: #0ccad8;
}
.ribbon-3 span:after {
	height: 6px;
	width: 8px;
	left: -8px;
	top: 0;
	border-radius: 8px 8px 0 0;
	background: #0ccad8;
}
.ribbon-3 i {
	color: #fff;
	font-size: 16px;
	padding-left: 7px;
	padding-right: 7px;
}