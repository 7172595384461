/*------------------------------------------------------------------
[Nav Styles Stylesheet]

Project        :   Templist - HTML5 Premium Digital goods marketplace directory jquery css responsive website Template
Version        :   V.1
Create Date    :   28/02/20
Copyright      :   Spruko Technologies Private Limited 
Author         :   SprukoSoft
Author URL     :   https://themeforest.net/user/sprukosoft
Support		   :   support@spruko.com
License        :   Licensed under ThemeForest License

-------------------------------------------------------------------*/

/** Nav Styles
/** Nav Tabs
/** Horizontal-dashboard menu
	
/*--Nav Styles--*/
.nav {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}
.nav-link {
	display: block;
	padding: .2rem .9rem;
}
.nav-link.active {
	color: #fff;
}
.nav-link:focus, .nav-link:hover {
	text-decoration: none;
}
.nav-link.disabled {
	color: #868e96;
}
.nav-tabs .nav-item {
	margin-bottom: -1px;
}
.nav-tabs .nav-link {
	border: 1px solid transparent;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
	border: 0;
}
.nav-tabs .nav-link.disabled {
	color: #868e96;
	background-color: transparent;
	border-color: transparent;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
	border: 0;
}
.nav-tabs .dropdown-menu {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.nav-pills .nav-link {
	border-radius: 4px;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
	color: #fff;
}
.nav-fill .nav-item {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	text-align: center;
}
.nav-justified .nav-item {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
	text-align: center;
}
.tab-content>.tab-pane {
	display: none;
}
.tab-content>.active {
	display: block;
}
.navbar {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: .5rem 1rem;
}
.navbar>.container, .navbar>.container-fluid {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.navbar-brand {
	display: inline-block;
	padding-top: .359375rem;
	padding-bottom: .359375rem;
	margin-right: 1rem;
	font-size: 1.125rem;
	line-height: inherit;
	white-space: nowrap;
}
.navbar-brand:focus, .navbar-brand:hover {
	text-decoration: none;
}
.navbar-nav {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}
.navbar-nav .nav-link {
	padding-right: 0;
	padding-left: 0;
}
.navbar-nav .dropdown-menu {
	position: static;
	float: none;
}
.navbar-text {
	display: inline-block;
	padding-top: .5rem;
	padding-bottom: .5rem;
}
.navbar-collapse {
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-ms-flex-align: center;
	align-items: center;
}
.navbar-toggler {
	padding: .25rem .75rem;
	font-size: 1.125rem;
	line-height: 1;
	background-color: transparent;
	border: 1px solid transparent;
	border-radius: 4px;
}
.navbar-toggler:focus, .navbar-toggler:hover {
	text-decoration: none;
}
.navbar-toggler:not(:disabled):not(.disabled) {
	cursor: pointer;
}
.navbar-toggler-icon {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	vertical-align: middle;
	content: "";
	background: no-repeat center center;
	background-size: 100% 100%;
}
@media (max-width:575.98px) {
	.navbar-expand-sm>.container, .navbar-expand-sm>.container-fluid {
		padding-right: 0;
		padding-left: 0;
	}
}
@media (min-width:576px) {
	.navbar-expand-sm {
		-ms-flex-flow: row nowrap;
		flex-flow: row nowrap;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}
	.navbar-expand-sm .navbar-nav {
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.navbar-expand-sm .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-sm .navbar-nav .nav-link {
		padding-right: .5rem;
		padding-left: .5rem;
	}
	.navbar-expand-sm>.container, .navbar-expand-sm>.container-fluid {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}
	.navbar-expand-sm .navbar-collapse {
		display: -ms-flexbox!important;
		display: flex!important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	}
	.navbar-expand-sm .navbar-toggler {
		display: none;
	}
}
@media (max-width:767.98px) {
	.navbar-expand-md>.container, .navbar-expand-md>.container-fluid {
		padding-right: 0;
		padding-left: 0;
	}
}
@media (min-width:768px) {
	.navbar-expand-md {
		-ms-flex-flow: row nowrap;
		flex-flow: row nowrap;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}
	.navbar-expand-md .navbar-nav {
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.navbar-expand-md .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-md .navbar-nav .nav-link {
		padding-right: .5rem;
		padding-left: .5rem;
	}
	.navbar-expand-md>.container, .navbar-expand-md>.container-fluid {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}
	.navbar-expand-md .navbar-collapse {
		display: -ms-flexbox!important;
		display: flex!important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	}
	.navbar-expand-md .navbar-toggler {
		display: none;
	}
}
@media (max-width:991.98px) {
	.navbar-expand-lg>.container, .navbar-expand-lg>.container-fluid {
		padding-right: 0;
		padding-left: 0;
	}
}
@media (min-width:992px) {
	.navbar-expand-lg {
		-ms-flex-flow: row nowrap;
		flex-flow: row nowrap;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}
	.navbar-expand-lg .navbar-nav {
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.navbar-expand-lg .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: .5rem;
		padding-left: .5rem;
	}
	.navbar-expand-lg>.container, .navbar-expand-lg>.container-fluid {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}
	.navbar-expand-lg .navbar-collapse {
		display: -ms-flexbox!important;
		display: flex!important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	}
	.navbar-expand-lg .navbar-toggler {
		display: none;
	}
}
@media (max-width:1279.98px) {
	.navbar-expand-xl>.container, .navbar-expand-xl>.container-fluid {
		padding-right: 0;
		padding-left: 0;
	}
}
@media (min-width:1280px) {
	.navbar-expand-xl {
		-ms-flex-flow: row nowrap;
		flex-flow: row nowrap;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}
	.navbar-expand-xl .navbar-nav {
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.navbar-expand-xl .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-xl .navbar-nav .nav-link {
		padding-right: .5rem;
		padding-left: .5rem;
	}
	.navbar-expand-xl>.container, .navbar-expand-xl>.container-fluid {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}
	.navbar-expand-xl .navbar-collapse {
		display: -ms-flexbox!important;
		display: flex!important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	}
	.navbar-expand-xl .navbar-toggler {
		display: none;
	}
}
.navbar-expand {
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-ms-flex-pack: start;
	justify-content: flex-start;
}
.navbar-expand>.container, .navbar-expand>.container-fluid {
	padding-right: 0;
	padding-left: 0;
}
.navbar-expand .navbar-nav {
	-ms-flex-direction: row;
	flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
	position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
	padding-right: .5rem;
	padding-left: .5rem;
}
.navbar-expand>.container, .navbar-expand>.container-fluid {
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
	display: -ms-flexbox!important;
	display: flex!important;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
}
.navbar-expand .navbar-toggler {
	display: none;
}
.navbar-light .navbar-brand {
	color: rgba(0, 0, 0, .9);
}
.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
	color: rgba(0, 0, 0, .9);
}
.navbar-light .navbar-nav .nav-link {
	color: rgba(0, 0, 0, .5);
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
	color: rgba(0, 0, 0, .7);
}
.navbar-light .navbar-nav .nav-link.disabled {
	color: rgba(0, 0, 0, .3);
}
.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
	color: rgba(0, 0, 0, .9);
}
.navbar-light .navbar-toggler {
	color: rgba(0, 0, 0, .5);
	border-color: rgba(0, 0, 0, .1);
}
.navbar-light .navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-light .navbar-text {
	color: rgba(0, 0, 0, .5);
}
.navbar-light .navbar-text a {
	color: rgba(0, 0, 0, .9);
}
.navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
	color: rgba(0, 0, 0, .9);
}
.navbar-dark .navbar-brand {
	color: #fff;
}
.navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
	color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
	color: rgba(255, 255, 255, .5);
}
.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
	color: rgba(255, 255, 255, .75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
	color: rgba(255, 255, 255, .25);
}
.navbar-dark .navbar-nav .active>.nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show>.nav-link {
	color: #fff;
}
.navbar-dark .navbar-toggler {
	color: rgba(255, 255, 255, .5);
	border-color: rgba(255, 255, 255, .1);
}
.navbar-dark .navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-dark .navbar-text {
	color: rgba(255, 255, 255, .5);
}
.navbar-dark .navbar-text a {
	color: #fff;
}
.navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
	color: #fff;
}
.nav-sidebar .list-unstyled li:last-child {
	border-bottom: 0;
}
.nav-sidebar li ul li:last-child {
	border-bottom: 0;
}

/*--- Nav Tabs--*/
.nav-item1 {
	padding: 10px;
	min-width: 2rem;
	transition: .3s color;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}
.nav1 {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	background: #eaebf7;
}
.nav-item1:hover:not(.disabled) {
	color: #fff;
}
.nav-item1.active {
	color: #fff;
}
.nav-item1 .nav-link.disabled {
	opacity: 6;
	cursor: default;
	pointer-events: none;
}
.nav1.bg-primary .nav-item1 .nav-link.disabled {
	color: rgba(255, 255, 255, .5);
}
.nav-tabs .nav-item1 {
	margin-bottom: -1px;
}
.nav-tabs .nav-item1.nav-link {
	border: 1px solid transparent;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}
.nav-tabs .nav-item1 .nav-link {
	border: 0;
	color: inherit;
	margin-bottom: -1px;
	color: #636d8c;
	transition: .3s border-color;
	font-weight: 400;
	padding: 1rem 10px;
}
.nav-tabs .nav-item1 .nav-link:hover:not(.disabled) {
	color: #fff;
}
.nav-tabs .nav-item1 .nav-link.active {
	color: #fff;
}
.nav-tabs .nav-item1 .nav-link.disabled {
	opacity: .6;
	cursor: default;
	pointer-events: none;
}
.nav-tabs .nav-item1 {
	margin-bottom: 0;
	position: relative;
}
.nav-tabs .nav-item1 i {
	margin-right: .25rem;
	line-height: 1;
	font-size: 0rem;
	width:.875rem;
	vertical-align: baseline;
	display: inline-block;
	margin-right: 10px;
}
.nav-tabs .nav-item1:hover .nav-submenu {
	display: block;
}
.nav-tabs .nav-submenu {
	display: none;
	position: absolute;
	background: #000;
	border: 1px solid #eaebf7;
	border-top: none;
	z-index: 10;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05);
	min-width: 10rem;
	border-radius: 0 0 3px 3px;
}
.nav-tabs .nav-submenu .nav-item1 {
	display: block;
	padding: .5rem 1rem;
	color: #636d8c;
	margin: 0!important;
	cursor: pointer;
	transition: .3s background;
}
.nav-tabs .nav-submenu .nav-item1.active {
	color: #096cf7;
}
.nav-tabs .nav-submenu .nav-item1:hover {
	color: #5f6877;
	text-decoration: none;
	background: rgba(0, 0, 0, .024);
}

.nav-unread {
	position: absolute;
	top: .1rem;
	right: .4rem;
	width: .5rem;
	height: .5rem;
	border-radius: 50%;
}
.nav-unread.badge {
	position: absolute;
	top: 0;
	text-align: center;
	right: 0;
	width: 1.2rem;
	height: 1.2rem;
	border-radius: 50%;
}
.nav-link.icon {
	margin: 5px;
	border-radius: 50%;
	padding: 12px;
	width: 2.5rem;
	text-align: center;
	height: 2.5rem;
	font-size: 15px;
	position: relative;
}
.nav-link.icon.full-screen-link {
	padding-bottom: 12px;
}
.nav-link.icon i {
	color: #fcfdff;
}
.header-brand {
	color: inherit;
	margin-right: 1rem;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
}
.app-header .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
	min-width: 200px;
	text-align: center;
}
.app-header .header-brand .header-brand-img {
	margin-right: 0;
}
.app-header1 .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
	min-width: 215px;
	text-align: center;
	margin-top: -1.5px;
}
.app-header1 .header-brand .header-brand-img {
	margin-right: 0;
}
.header-brand:hover {
	color: inherit;
	text-decoration: none;
}
.header-brand-img {
	height: 2.5rem;
	line-height: 2rem;
	vertical-align: bottom;
	margin-right: .5rem;
	width: auto;
}
.header-avatar {
	width: 2rem;
	height: 2rem;
	display: inline-block;
	vertical-align: bottom;
	border-radius: 50%;
}
.header-btn {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	line-height: 2rem;
	text-align: center;
	font-size: 1rem;
}
.center-block {
	margin-right: auto;
	margin-left: auto;
	float: inherit!important;
}
.header-btn.has-new {
	position: relative;
}
.header-btn.has-new:before {
	content: '';
	width: 6px;
	height: 6px;
	background: #f84242;
	position: absolute;
	top: 4px;
	right: 4px;
	border-radius: 50%;
}
.header-toggler {
	width: 2rem;
	height: 2rem;
	position: relative;
	color: #2c2c2c;
}
.header-toggler:hover {
	color:#2c2c2c;
}
.header-toggler-icon {
	position: absolute;
	width: 1rem;
	height: 2px;
	color: inherit;
	background: currentColor;
	border-radius: 4px;
	top: 50%;
	left: 50%;
	margin: -2px 0 0 -.5rem;
	box-shadow: 0 5px currentColor, 0 -5px currentColor;
}

.nav-item, .nav-link {
	min-width: 2rem;
	transition: .3s color;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}
.user-img {
	margin-top: 2px;
}
.header .nav-item .badge, .header .nav-link .badge {
	position: absolute;
	top: 0;
	right: -1px;
	padding: .2rem .25rem;
	min-width: 1rem;
	font-size: 13px;
}
#headerMenuCollapse .nav-item .badge, #headerMenuCollapse .nav-link .badge {
	position: relative;
	min-width: 1rem;
	font-size: 10px;
	font-weight: 500;
}
.nav-tabs {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #000;
	margin: 0 -0.30rem;
	border-bottom: 0;
}
.nav-tabs .nav-link {
	border: 0;
	border-radius: 0;
	color: inherit;
	color: #2e384d;
	transition: .3s border-color;
	font-weight: 500;
	padding: 1rem 1.6rem;
	font-size: 13px;
	text-align: center;
}
.navtab-wizard li:first-of-type a {
	border-left: none!important;
}
.navtab-wizard li:first-of-type a:active, .navtab-wizard li:first-of-type a:focus, .navtab-wizard li:first-of-type a:hover {
	border-left: none!important;
}
.navtab-wizard li:last-of-type a {
	border-right: none!important;
}
.navtab-wizard li:last-of-type a:active, .navtab-wizard li:last-of-type a:focus, .navtab-wizard li:last-of-type a:hover {
	border-right: none!important;
}
.navtab-wizard.nav-tabs .nav-link {
	width: 100%;
	text-align: center;
	display: block;
	border-radius: 0;
	font-size: 16px;
	font-weight: 400;
	border-left: 1px solid #eaebf7;
}
.navtab-wizard {
	border-bottom: 1px solid #eaebf7!important;
}
@media (max-width:480px) {
	.tab-content.card-body .btn {
		min-width: 1.625rem;
	}
	.tab-content.card-body .progress-bar {
		display: none;
	}
}
@media (max-width:560px) {
	.navtab-wizard.nav-tabs li {
		width: 100%;
		display: contents;
	}
}
.nav-tabs .nav-link.disabled {
	opacity: .4;
	cursor: default;
	pointer-events: none;
}
.nav-tabs .nav-item {
	margin-bottom: 0;
	position: relative;
}
.nav-tabs .nav-item:hover .nav-submenu {
	display: block;
}
.nav-item:hover .dropdown-menu, .nav-link:hover .dropdown-menu {
	display: block;
}
.nav-link:hover .dropdown-menu.show {
	display: block;
}
.admin-navbar {
	background: #fff;
	box-shadow: 0 22px 68px -9px rgba(0, 0, 0, .15);
}
.admin-navbar .container {
	position: relative;
}
.admin-navbar .nav {
	padding: 0;
	margin: 0;
}
.admin-navbar .nav .nav-link {
	position: relative;
}
.nav-item .nav-link:hover .mega-dropdown .nav-item .nav-link .sub-item {
	display: block;
}
.nav-item:hover .sub-item, .nav-link:hover .sub-item {
	display: block;
}
.nav-link:hover .sub-item {
	display: block;
}
.admin-navbar .nav-item {
	text-align: center;
	position: static;
}
@media (min-width:992px) {
	.admin-navbar .nav-item {
		position: relative;
	}
}
.admin-navbar .nav-item.active .nav-link {
	background-color: #fff;
	background-repeat: repeat-x;
	border-width: 0;
}
.admin-navbar .nav-item.active+.nav-item .nav-link {
	border-left-width: 0;
}
.admin-navbar .nav-item:last-child .nav-link {
	border-right-width: 1px;
}
.admin-navbar .nav-item.with-sub>a::after {
	content: '\f107';
	font-family: FontAwesome;
	font-weight: 400;
	margin-left: 4px;
	margin-top: 0;
	opacity: .7;
}
@media (min-width:992px) {
	.admin-navbar .nav-item.with-sub>a::after {
		margin-left: 8px;
	}
}
.admin-navbar .nav-item.with-sub:focus>.sub-item, .admin-navbar .nav-item.with-sub:hover>.sub-item {
	display: block;
}
.admin-navbar .nav-link {
	border: 0;
	color: inherit;
	color: #636d8c;
	transition: .3s border-color;
	font-weight: 500;
	padding: 1rem 1.42rem;
	font-size: 13px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	border-right-width: 0;
	text-transform: uppercase;
}
.admin-navbar .nav-link .icon {
	font-size: 21px;
	line-height: 0;
}
.admin-navbar .nav-link i {
	margin-right: 8px;
}
.admin-navbar .nav-link .icon.ion-ios-analytics-outline {
	font-size: 20px;
	margin-top: -2px;
}
.admin-navbar .nav-link.active {
	font-weight: 600;
	border-bottom: 1px solid;
}
.admin-navbar .nav-link .square-8 {
	margin-left: 3px;
	background-color: #fff;
	border-radius: 100%;
}
@media (min-width:992px) {
	.admin-navbar .nav-link .square-8 {
		margin-left: 5px;
	}
}
@media (min-width:992px) {
	.admin-navbar .nav-link span :not(.square-8) {
		display: block;
	}
}
@media (max-width:992px) {
	.admin-navbar .nav-link span :not(.square-8) {
		display: block;
	}
	.admin-navbar .nav-link {
		width: 100%;
		text-align: left;
		display: block;
	}
	.admin-navbar .nav-item {
		min-width: 100%;
	}
	.admin-navbar.collapse.show {
		display: block;
	}
	.admin-navbar {
		display: none;
	}
	.admin-navbar .mega-dropdown .sub-item .section-label {
		margin-top: 10px;
	}
	.admin-navbar .sub-item .sub-with-sub ul li a {
		padding-left: 20px;
	}
	.admin-navbar .nav .nav-item.with-sub .nav-link.active {
		position: relative!important;
	}
	.admin-navbar .nav .nav-link .sub-item {
		position: inherit!important;
	}
	.admin-navbar .container {
		background: #fff;
		z-index: 100;
	}
	.admin-navbar .sub-item {
		position: relative!important;
		top: 0!important;
	}
	.nav-item.with-sub, .with-sub .nav-link {
		display: block!important;
	}
	.about-con {
		border-bottom: 1px solid #eaebf7;
	}
}
.admin-navbar .mega-dropdown {
	position: static;
}
.admin-navbar .mega-dropdown .sub-item {
	padding: 25px;
	width: 100%;
}
.admin-navbar .mega-dropdown .sub-item a {
	padding: 5px 9px;
	margin: 0;
}
.admin-navbar .mega-dropdown .sub-item .section-label {
	border-bottom: 1px solid rgb(137, 42, 226, .1);
	padding-bottom: 5px;
	margin-bottom: 15px;
	font-size: 14px;
	font-weight: 500;
	display: block;
	text-align: left;
	letter-spacing: 1px;
}
.admin-navbar .sub-item {
	background-color: #fff;
	position: absolute;
	top: 51px;
	left: 0;
	border-top: 0;
	padding: 10px 0;
	box-shadow: 0 22px 68px -9px rgba(0, 0, 0, .15);
	width: 100%;
	font-size: 14px;
	font-weight: 400;
	z-index: 9999;
	display: none;
	color: #111;
}
@media (min-width:992px) {
	.admin-navbar .sub-item {
		width: 200px;
	}
}
@media (max-width:1279px) and (min-width:992px) {
	.admin-navbar .nav-link {
		padding: 1rem .57rem;
	}
	.pabout.p-8 {
		padding: 17px!important;
	}
	.hor-menu-nav li a{
		font-size:11px !important
	}
}
.admin-navbar .sub-item ul {
	list-style: none;
	padding: 0;
	margin: 0;
	text-align: left;
}
.admin-navbar .sub-item ul a {
	display: block;
	padding: .5rem 1.5rem;
	color: #636d8c;
}
.admin-navbar .sub-item ul a:active {
	color: #fff;
	text-decoration: none;
}
.admin-navbar .sub-item .sub-with-sub {
	position: relative;
}
.admin-navbar .sub-item .sub-with-sub::after {
	content: '\f054';
	font-family: FontAwesome;
	font-size: 10px;
	position: absolute;
	top: 9px;
	right: 20px;
	color: #636d8c;
}
.admin-navbar .sub-item .sub-with-sub ul {
	display: none;
	padding-left: 15px;
}
@media (min-width:992px) {
	.admin-navbar .sub-item .sub-with-sub ul {
		padding-left: 0;
		position: absolute;
		top: 0;
		right: -200px;
		width: 200px;
		background-color: #fff;
		padding: 10px 0;
		box-shadow: 0 22px 68px -9px rgba(0, 0, 0, .15);
	}
}
.admin-navbar .sub-item .sub-with-sub ul a {
	display: block;
	padding: 6px 10px;
}
.admin-navbar .sub-item .sub-with-sub:focus ul, .admin-navbar .sub-item .sub-with-sub:hover ul {
	display: block;
}
@media (max-width:1279px) and (min-width:992px) {
	.nav-tabs .nav-link {
		padding: 1rem .95rem;
		font-size: 13px;
	}
}
.nav-tabs .nav-item i {
	margin-right: .25rem;
	line-height: 1;
	width:.875rem;
	vertical-align: baseline;
	display: inline-block;
	margin-right: 10px;
	color: #546473;
}
.nav-tabs .nav-link.active i{
	color:#fff;
}
.nav-tabs .nav-link:hover i {
    color: #fff;
}
.nav-tabs .nav-submenu {
	display: none;
	position: absolute;
	background: #fff;
	border: 1px solid #eaebf7;
	border-top: none;
	z-index: 10;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05);
	min-width: 10rem;
	border-radius: 0 0 3px 3px;
}
.nav-tabs .nav-submenu .nav-item {
	display: block;
	padding: .5rem 1rem;
	color: #636d8c;
	margin: 0!important;
	cursor: pointer;
	transition: .3s background;
}
.nav-tabs .nav-submenu .nav-item.active {
	color: #096cf7;
}
.nav-tabs .nav-submenu .nav-item:hover {
	color: #5f6877;
	text-decoration: none;
	background: rgba(0, 0, 0, .024);
}

.header-main .social-icons {
	float: right;
}
.header-main .social-icons li {
	color: #212529;
	margin-right: 35px;
	display: inline-block;
	list-style: none;
	font-size: 20px;
}
.header-main .social-icons li a {
	color: #212529;
}
.header-search {
	padding: 1.5rem 1.5rem;
}
.header-search .header-icons .header-icons-link {
	display: inline-flex;
	margin-bottom: 0;
}
.header-icons-link .header-icons-link1 i{
	color:#636d8c;
}
.header-search .header-icons .header-icons-link li a{
	background:rgba(0,0,0,0.2);
	width: 40px;
	height: 40px;
	border-radius: 50px;
	text-align: center;
	line-height: 2;
	margin-right: 5px;
	display: inline-block;
	margin-top:4px;
}
.header-search .header-icons .header-icons-link .header-icons-link1, .header-search .header-icons .header-icons-link .header-icons-link2 {
	width: 2.2rem;
    text-align: center;
    height: 2.2rem;
    position: relative;
    line-height: 2.2;
    vertical-align: middle;
    font-size: 18px;
	border-radius:4px;
	margin-top:2px;
}
.header-search .header-icons .header-icons-link .header-icons-link1 img{
	padding:7px;
}
.header-search .header-icons .main-badge1 {
    position: absolute;
    top: -5px;
    right: -5px;
    text-align: center;
    font-size: 10px;
	padding: 3px 5px !important;
}
.header-search .header-icons .header-icons-link.icons li {
	background: #fff;
}
.top-bar {
	border-bottom: 1px solid rgba(0,0,0,0.2);
    background: rgba(0,0,0,0.1);
}
.top-bar-left ul {
	margin-bottom: 0;
}
.top-bar .top-bar-right {
	float: right;
}
.top-bar .top-bar-left {
	display: flex;
}
.top-bar .top-bar-left .socials li{
	display: inline-block;
	float: left;
	font-size: 15px;
	padding: 9px 15px 8px 15px;
	margin: 0;
	border-left: 1px solid #e6e7f5;
	cursor:pointer;
	margin: 0 !important;
}
.top-bar .top-bar-left .contact li {
	display: inline-block;
	color: #2e384d;
	border-left: 1px solid #e6e7f5;
	padding: 9px 15px 8px 15px;
	margin: 0;
}
.top-bar .top-bar-left .contact li:last-child{
	border-right:1px solid #e6e7f5;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #2e384d!important;
}
.top-bar .top-bar-right {
	display: flex;
}
.header-search .header-inputs .input-group-append.searchicon {
	top: 10px;
	right: 25px;
	position: relative;
}
.header-search .header-search-logo {
	margin-right: 1rem;
	z-index: 1;
}
.header-search .header-nav .nav-cart .icon-cart i {
	font-size: 18px;
	color: #636d8c;
}
.header-search .header-nav .nav-cart .icon-cart a {
	color: #636d8c;
}
.header-nav {
	display: flex;
}
.header-links {
	padding: 3px;
}
.header-links li {
	margin: 5px;
}
.header-links li a {
	padding: 9px 18px;
	border-radius: 4px;
}
.header-style .horizontalMenu {
	float: inherit !important;
}
.top-bar .select2-dropdown.select2-dropdown--below {
	width: 180px !important;
}
.top-bar .select-country .select2-container {
	width: 150px !important;
}

/** Horizontal-dashboard menu */
.hor-menu-nav{
	background:#fff;
	margin-bottom: 1.5rem;
	border:1px solid #eaebf7;
	border-radius: 4px;
}
.hor-menu-nav li a{
	padding: 15px 14px !important;
	margin-right:1px;
	font-weight: 500; 
	color: #2e384d !important;
	border-left:1px solid #eaebf7;
	font-size:15px;
}
.hor-menu-nav li .nav-icon{
	margin-right:6px;
}
.header-text .form-control, .header-text1  .form-control{
	border:0;
}
@media (min-width:992px) {	
	.sticky-wrapper.is-sticky .horizontal-main {
		padding: 0.35rem;
		box-shadow: 2px 3px 4.7px .3px rgba(0, 0, 0, .24);
	}
	.sticky-wrapper.is-sticky .horizontal-main .ad-post {
		margin-top: .63rem!important;
	}
	.sticky-wrapper.is-sticky .horizontalMenu>.horizontalMenu-list>li>a>.fa {
		color: #2e384d;
	}
	.sticky-wrapper.is-sticky .horizontalMenu>.horizontalMenu-list>li>a.active .fa {
		color: #fff!important;
	}
	.sticky-wrapper.is-sticky .desktoplogo-1 {
		display: block!important;
	}
	.sticky-wrapper.is-sticky .desktoplogo-1 {
		padding: 0.65rem 0;
		margin: 0;
		float: left;
	}
	.sticky-wrapper.is-sticky .header-style1 .top-postbtn{
		margin-top:0 !important;
	}
	.sticky-wrapper.is-sticky .horizontalMenu>.horizontalMenu-list>li {
		padding: 0.70rem 0;
	}
	.desktoplogo-1 {
		display: none;
	}
	.sticky-wrapper.is-sticky .desktoplogo {
		display: none;
	}
}