/*------------------------------------------------------------------
[Card Styles]

Project        :   Templist - HTML5 Premium Digital goods marketplace directory jquery css responsive website Template
Version        :   V.1
Create Date    :   28/02/20
Copyright      :   Spruko Technologies Private Limited 
Author         :   SprukoSoft
Author URL     :   https://themeforest.net/user/sprukosoft
Support		   :   support@spruko.com
License        :   Licensed under ThemeForest License

-------------------------------------------------------------------*/
.card {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid #dbddf1;
	border-radius: 4px;
	box-shadow:0 0 12px 0 rgba(21, 17, 148, 0.06);
}
.item .card{
	box-shadow:0 5px 10px rgba(21, 17, 148, 0.06) !important;
}
.card>hr {
	margin-right: 0;
	margin-left: 0;
}
.card>.list-group:first-child .list-group-item:first-child {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}
.card>.list-group:last-child .list-group-item:last-child {
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}
.card-body {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	padding: 1.5rem;
}
.card-title {
	margin-bottom: 1.5rem;
}
.card-subtitle {
	margin-top: -.75rem;
	margin-bottom: 0;
}
.card-text:last-child {
	margin-bottom: 0;
}
.card-link:hover {
	text-decoration: none;
}
.card-link+.card-link {
	margin-left: 1.5rem;
}
.card-header {
	padding: 1.5rem 1.5rem;
	margin-bottom: 0;
	background-color: rgba(0, 0, 0, .03);
	border-bottom: 1px solid #eaebf7;
}
.card-header+.list-group .list-group-item:first-child {
	border-top: 0;
}
.card-footer {
	padding: 1.5rem 1.5rem;
	background-color: rgba(0, 0, 0, .03);
	border-top: 1px solid #eaebf7;
}
.card-footer:last-child {
	border-radius: 0 0 calc(3px - 1px) calc(3px - 1px);
}
.card-header-tabs {
	margin-right: -.75rem;
	margin-bottom: -1.5rem;
	margin-left: -.75rem;
	border-bottom: 0;
}
.card-header-pills {
	margin-right: -.75rem;
	margin-left: -.75rem;
}
.card-img-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1.25rem;
}
.card-img {
	width: 100%;
	border-radius: calc(3px - 1px);
	height: 100%;
}
.card-img-top {
	width: 100%;
	border-top-left-radius: calc(3px - 1px);
	border-top-right-radius: calc(3px - 1px);
}
.card-img-bottom {
	width: 100%;
	border-bottom-right-radius: calc(3px - 1px);
	border-bottom-left-radius: calc(3px - 1px);
}
.card-deck {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
}
.card-deck .card {
	margin-bottom: .75rem;
}
@media (min-width:576px) {
	.card-deck {
		-ms-flex-flow: row wrap;
		flex-flow: row wrap;
		margin-right: -.75rem;
		margin-left: -.75rem;
	}
	.card-deck .card {
		display: -ms-flexbox;
		display: flex;
		-ms-flex: 1 0 0%;
		flex: 1 0 0%;
		-ms-flex-direction: column;
		flex-direction: column;
		margin-right: .75rem;
		margin-bottom: 0;
		margin-left: .75rem;
	}
}
.card-group {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
}
.card-group>.card {
	margin-bottom: .75rem;
}
@media (min-width:576px) {
	.card-group {
		-ms-flex-flow: row wrap;
		flex-flow: row wrap;
	}
	.card-group>.card {
		-ms-flex: 1 0 0%;
		flex: 1 0 0%;
		margin-bottom: 0;
	}
	.card-group>.card+.card {
		margin-left: 0;
		border-left: 0;
	}
	.card-group>.card:first-child {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	.card-group>.card:first-child .card-header, .card-group>.card:first-child .card-img-top {
		border-top-right-radius: 0;
	}
	.card-group>.card:first-child .card-footer, .card-group>.card:first-child .card-img-bottom {
		border-bottom-right-radius: 0;
	}
	.card-group>.card:last-child {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
	.card-group>.card:last-child .card-header, .card-group>.card:last-child .card-img-top {
		border-top-left-radius: 0;
	}
	.card-group>.card:last-child .card-footer, .card-group>.card:last-child .card-img-bottom {
		border-bottom-left-radius: 0;
	}
	.card-group>.card:only-child {
		border-radius: 4px;
	}
	.card-group>.card:only-child .card-header, .card-group>.card:only-child .card-img-top {
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}
	.card-group>.card:only-child .card-footer, .card-group>.card:only-child .card-img-bottom {
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;
	}
	.card-group>.card:not(:first-child):not(:last-child):not(:only-child) {
		border-radius: 0;
	}
	.card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-footer, .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-header, .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom, .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-img-top {
		border-radius: 0;
	}
}
.card-columns .card {
	margin-bottom: 1.5rem;
}
@media (min-width:576px) {
	.card-columns {
		-webkit-column-count: 3;
		-moz-column-count: 3;
		column-count: 3;
		-webkit-column-gap: 1.25rem;
		-moz-column-gap: 1.25rem;
		column-gap: 1.25rem;
		orphans: 1;
		widows: 1;
	}
	.card-columns .card {
		display: inline-block;
		width: 100%;
	}
}
.card-blog-overlay .card-header {
	border-bottom: 1px solid rgba(255, 255, 255, .2);
}
.card-blog-overlay1 .card-footer, .card-blog-overlay2 .card-footer {
	border-top: 1px solid rgba(255, 255, 255, .2);
}
.card-blog-overlay {
	background: url(../../assets/images/media/thumbnails/thumb2.jpg);
	background-size: cover;
	position: relative;
}
.card-blog-overlay1:before, .card-blog-overlay2:before, .card-blog-overlay:before {
	content: '';
	display: block;
	position: absolute;
	background: rgba(0, 0, 0, 0.7);
	width: 100%;
	height: 100%;
	right: 0;
	top: 0;
}
.card-blog-overlay1 {
	background: url(../../assets/images/media/thumbnails/thumb3.jpg);
	background-size: cover;
}
.card-blog-overlay2 {
	background: url(../../assets/images/banners/banner3.jpg);
	background-size: cover;
}
.card-blog-overlay3 {
	background: url(../../assets/images/media/0-4.jpg);
	background-size: cover;
}
.card-collapsed>:not(.card-header):not(.card-status) {
	display: none;
}
.card-collapsed .card-options-collapse i:before {
	content: '\e92d';
}
.card-fullscreen .card-options-fullscreen i:before {
	content: '\e992';
}
.card-fullscreen .card-options-remove {
	display: none;
}
.card-map {
	height: 15rem;
	background: #eaebf7;
}
.card-map-placeholder {
	background: no-repeat center;
}
.card-tabs {
	display: -ms-flexbox;
	display: flex;
}
.card-tabs-bottom .card-tabs-item {
	border: 0;
}
.card-tabs-bottom .card-tabs-item.active {
	border-top-color: #fff;
}
.card-tabs-item {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	display: block;
	padding: 1.4rem 1.5rem;
	color: inherit;
	overflow: hidden;
}
a.card-tabs-item {
	background: #fafbfc;
}
a.card-tabs-item:hover {
	text-decoration: none;
	color: inherit;
}
a.card-tabs-item:focus {
	z-index: 1;
}
a.card-tabs-item.active {
	background: #fff;
	border-bottom-color: #fff;
}
.card-status {
	position: absolute;
	top: -1px;
	left: 0;
	right: 0;
	height: 3px;
	border-radius: 4px 7px 0 0;
	background: rgba(0, 40, 100, .12);
}
.card-status-left {
	right: auto;
	bottom: 0;
	height: auto;
	width: 3px;
	border-radius: 4px 0 0 3px;
}
.card-icon {
	width: 3rem;
	font-size: 2.5rem;
	line-height: 3rem;
	text-align: center;
}
.card-fullscreen {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	margin: 0;
}
.card-alert {
	border-radius: 0;
	margin: -1px -1px 0;
}
.card-category {
	font-size: 18px;
	text-transform: uppercase;
	text-align: center;
	font-weight: 500;
	letter-spacing: .05em;
	margin: 0 0 .5rem;
	background: #eaebf7;
}